import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { TextInput } from '@eucalyptusvc/design-system';
import { QuestionProps } from './types';
import {
  combineRules,
  useValidMaskedDate,
  useMaxMaskedDateExclusive,
  useMinMaskedDateInclusive,
  useRequiredValidation,
  getMaskedDateFormat,
} from '@customer-frontend/utils';
import { ProblemType, QuizQuestion } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';
import { Brand } from '@customer-frontend/types';
import { differenceInCalendarYears, isMatch, parse } from 'date-fns';

export function DateQuestionInput({
  question,
  isLoading,
  problemType,
}: QuestionProps): React.ReactElement {
  const { brand, dateConfig } = getConfig();
  const { register, errors } = useFormContext();
  const { required } = question;

  const validationRules = useValidationRules(question, brand, problemType);

  return (
    <TextInput
      autoFocus
      autoComplete="off"
      name={question.id}
      disabled={isLoading}
      ref={register(validationRules)}
      palette={brand === 'pilot' ? 'alternate' : 'default'}
      type={dateConfig.type}
      required={required ?? false}
      errorMessage={errors[question.id]?.message}
      placeholder={dateConfig.placeholder}
    />
  );
}

const useValidationRules = (
  question: QuizQuestion,
  brand: Brand,
  problemType?: ProblemType,
): RegisterOptions => {
  const { required, shortcode } = question;
  const { formatMessage } = useIntl();

  const MAX = new Date();
  MAX.setFullYear(new Date().getFullYear() - 14);

  const maxDateRule = useMaxMaskedDateExclusive(MAX);
  const minDateRule = useMinMaskedDateInclusive(new Date('1900-01-01'));
  const requiredRule = useRequiredValidation(
    formatMessage({ defaultMessage: 'Answer' }),
  );

  const rules = [useValidMaskedDate()];

  if (required) {
    rules.push(requiredRule);
  }

  if (shortcode === 'dob') {
    if (
      brand === 'pilot' &&
      (problemType === 'ERECTILE' || problemType === 'PREMATURE')
    ) {
      rules.push(minDateRule, {
        validate: {
          minAge: (value: string): boolean | string => {
            if (!isMatch(value, getMaskedDateFormat())) {
              // Ignore the validation when input is not a valid date
              return true;
            }

            return (
              differenceInCalendarYears(
                new Date(),
                parse(value, getMaskedDateFormat(), new Date()),
              ) >= 18 ||
              formatMessage({
                defaultMessage:
                  'You need to be over 18 to be eligible for treatment with Pilot.',
                description:
                  'Error message prompting users to enter a date that proves they are over 18 years old',
              })
            );
          },
        },
      });
    } else {
      rules.push(maxDateRule, minDateRule);
    }
  }

  return combineRules(...rules);
};
