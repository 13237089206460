import React, { useMemo, useState, useRef } from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionItem,
  AccordionButton,
  Collapse,
} from '@chakra-ui/react';
import {
  AccordionPalette,
  Button,
  Card,
  CardPalette,
  Divider,
  LoadingSpinner,
  TextInput,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Doctor } from '../../assets/doctor.svg';
import { ReactComponent as InfoCircle } from '../../assets/info-circle.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg';
import { ReactComponent as ArrowLink } from '../../assets/arrow-link.svg';
import { ReactComponent as ClockForward } from '../../assets/clock-forward.svg';
import { ReactComponent as DeleteCrossClose } from '../../assets/delete-cross-close.svg';
import { ReactComponent as ShoppingTag } from '../../assets/shopping-tag.svg';
import { ReactComponent as AddPlus } from '../../assets/add-plus.svg';
import {
  OrderTimeline,
  OrderTimelineProps,
  StageStyle,
} from '@customer-frontend/treatment';
import { HiArrowPath } from 'react-icons/hi2';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AddCouponCodesToPurchaseSyncGroupMutation,
  AddCouponCodesToPurchaseSyncGroupMutationVariables,
  OfferingPlanQuery,
  OfferingPlanQueryVariables,
  ProblemType,
  ProductType,
  PsgTimelineFragment,
} from '@customer-frontend/graphql-types';
import {
  usePurchaseCardContent,
  formatCentsToCurrency,
} from '@customer-frontend/order';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getZendeskRequestUrl, useTitle } from '@customer-frontend/utils';
import { DelayModal } from './delay-modal';
import clsx from 'clsx';
import { useThemeExtension } from '@eucalyptusvc/design-system/src/theme/shared';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { Logger } from '@customer-frontend/logger';
import { format, isBefore } from 'date-fns';
import { notificationService } from '@customer-frontend/notifications';
import { useForm } from 'react-hook-form';

type SyncGroup = NonNullable<
  NonNullable<
    NonNullable<OfferingPlanQuery['profile']>['purchases']
  >[number]['syncGroups']
>[number];

type SyncGroupOrder = { psgId: string } & NonNullable<SyncGroup['nextOrder']>;

export type OrderTimelineItemPalette = Record<
  'next' | 'paid' | 'fulfilled' | 'strikethroughColor',
  string
>;

export type NextOrderPalette = Record<
  | 'cardBorder'
  | 'textColor'
  | 'borderRadius'
  | 'headerBgColor'
  | 'orderTopBorder'
  | 'manageOrderTextColor'
  | 'totalBgColor'
  | 'modalNoteBgColor',
  string
>;

export type ProblemTypeIcons = Partial<
  Record<
    ProblemType | 'default',
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  >
>;

// todo: https://linear.app/eucalyptus/issue/TXN-2243/[customer-frontend]-remove-ff-and-old-timeline-code
// move the contents of this function into the OfferingPlanPage component when old timeline is removed
function getOrderTimelinePropsForPsgTimeline({
  syncGroups,
  shouldShowAllItems,
  palette,
  logger,
}: {
  syncGroups: PsgTimelineFragment[];
  shouldShowAllItems?: boolean;
  palette: OrderTimelineItemPalette;
  logger: Logger;
}): OrderTimelineProps {
  let items: NonNullable<PsgTimelineFragment['timeline']> = [];
  for (const psg of syncGroups ?? []) {
    const rxCsc = psg.sequenceContexts?.find(
      (sc) => sc.sequence?.__typename === 'PrescribableSequence',
    );
    if (!rxCsc) {
      continue;
    }

    if (!psg.timeline) {
      logger.error('expected to find timeline for rx csc', {
        purchase_sync_group_id: psg.id,
        sequence_context_id: rxCsc.id,
      });
      break;
    }

    items = psg.timeline;
    break;
  }

  if (items.length === 0) {
    return {
      orders: [],
    };
  }

  let nextOrderIdx = items.findIndex((item) => item.status === 'UPCOMING');
  if (nextOrderIdx < 0) {
    nextOrderIdx = items.length;
  }
  const currentOrderIdx = Math.max(nextOrderIdx - 1, 0);

  const visibleTimelineItems = items.slice(
    shouldShowAllItems ? 0 : currentOrderIdx,
  );

  const orders: OrderTimelineProps['orders'] = [];

  for (let idx = 0; idx < visibleTimelineItems.length; idx += 1) {
    const tli = visibleTimelineItems[idx];

    let title;
    switch (true) {
      case tli.id === items.at(nextOrderIdx)?.id:
        title = (
          <FormattedMessage
            defaultMessage="Next order"
            description="Offering plan page"
          />
        );
        break;

      case tli.status === 'FULFILLED':
        title = (
          <FormattedMessage
            defaultMessage="Past order"
            description="Offering plan page"
          />
        );
        break;
      case tli.status === 'PLACED':
        title = (
          <FormattedMessage
            defaultMessage="Previous order"
            description="Offering plan page"
          />
        );
        break;
      default:
        title = (
          <FormattedMessage
            defaultMessage="Scheduled order"
            description="Offering plan page"
          />
        );
        break;
    }

    const products = [...(tli.products ?? []), ...(tli.addonProducts ?? [])];

    const TimelineOrderSummary = ({
      orderDate,
      allProducts,
    }: {
      orderDate: Date;
      allProducts: Array<{
        id: string;
        displayName: string;
        type: ProductType;
      }>;
    }) => {
      const [showAllProducts, setShowAllProducts] = useState(false);

      const dateStr = isBefore(orderDate, new Date()) ? (
        <FormattedMessage
          defaultMessage="Paid: {formattedDate}"
          description="Offering plan page"
          values={{ formattedDate: format(orderDate, 'dd/MM/yyyy') }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="Payment: {formattedDate}"
          description="Offering plan page"
          values={{ formattedDate: format(orderDate, 'dd/MM/yyyy') }}
        />
      );

      const rxProducts = allProducts.filter((p) => p.type === 'RX');
      const nonRxProducts = allProducts.filter((p) => p.type !== 'RX');

      return (
        <div>
          <Typography size="paragraph" isBold>
            <FormattedMessage
              defaultMessage="Order summary"
              description="Offering plan page"
            />
          </Typography>
          <Typography size="paragraph">{dateStr}</Typography>
          {rxProducts.length > 0 && (
            <div className="gap-2">
              {rxProducts.map((p) => (
                <Typography size="paragraph" key={p.id}>
                  {p.displayName}
                </Typography>
              ))}
            </div>
          )}
          {showAllProducts && (
            <div className="gap-2">
              {nonRxProducts.map((p) => (
                <Typography size="paragraph" key={p.id}>
                  {p.displayName}
                </Typography>
              ))}
            </div>
          )}
          {nonRxProducts.length > 0 && (
            <button
              onClick={() => setShowAllProducts(!showAllProducts)}
              className="cursor-pointer"
            >
              <Typography decoration="underline" size="paragraph">
                {showAllProducts ? (
                  <FormattedMessage
                    defaultMessage="Hide items"
                    description="Offering plan page"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Show all items"
                    description="Offering plan page"
                  />
                )}
              </Typography>
            </button>
          )}
        </div>
      );
    };

    const timelineOrderSummary = (
      <TimelineOrderSummary
        allProducts={products}
        orderDate={new Date(tli.date)}
      />
    );

    let strikethroughPrice: React.ReactNode | undefined;

    const discount =
      tli.preDiscountTotalAmount.baseUnitAmount -
      tli.totalAmount.baseUnitAmount;

    if (discount !== 0) {
      strikethroughPrice = (
        <Typography size="medium-paragraph">
          <span className={clsx(palette.strikethroughColor, 'line-through')}>
            {formatCentsToCurrency(tli.preDiscountTotalAmount.baseUnitAmount, {
              omitZeroDecimals: true,
            })}
          </span>
        </Typography>
      );
    }

    const nextTli = visibleTimelineItems.at(idx + 1);

    let stage: StageStyle = 'circle';
    if (tli.status === 'FULFILLED') {
      stage = 'circleFilled';
    }

    if (
      (tli.status === 'UPCOMING' && idx === 0) ||
      (nextTli && nextTli.id === items.at(nextOrderIdx)?.id) ||
      (tli.status === 'PLACED' && tli.id === items.at(currentOrderIdx)?.id)
    ) {
      stage = 'circleStrong';
    }

    orders.push({
      id: tli.id,
      title,
      stage,
      primaryLabel: timelineOrderSummary,
      price: formatCentsToCurrency(tli.totalAmount.baseUnitAmount, {
        omitZeroDecimals: true,
      }),
      strikethroughPrice,
    });
  }

  orders.push({
    id: 'doctor-check-in',
    title: (
      <FormattedMessage
        defaultMessage="Practitioner check-in"
        description="Title in the timeline component showing a practitioners check-in after all orders are processed"
      />
    ),
    primaryLabel: (
      <div className="flex flex-row gap-x-2">
        <Doctor />
        <div>
          <FormattedMessage
            defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will check in with you once you have completed your treatment plan."
            values={{ isGb: getConfig().countryCode === 'GB' }}
          />
        </div>
      </div>
    ),
    stage: items.every((i) => i.status === 'FULFILLED')
      ? 'circleStrong'
      : 'circle',
  });

  return {
    orders,
  };
}

export type OfferingPlanPageProps = {
  profileRoute: string;
  switchRoute: (purchaseId: string) => string;
  palette: {
    orderTimelineItem: OrderTimelineItemPalette;
    refillCard?: CardPalette;
    nextOrder?: NextOrderPalette;
  };
  logger: Logger;
};

export function OfferingPlanPage({
  profileRoute,
  switchRoute,
  palette,
  logger,
}: OfferingPlanPageProps): React.ReactElement {
  const history = useHistory();
  const config = getConfig();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const urlParams = new URLSearchParams(window.location.search);
  const focusOnDelay = urlParams.get('focus') === 'delay';
  const initialJumpCompleted = useRef(false);

  const [upcomingOrderToDelay, setUpcomingOrderToDelay] = useState<
    { syncGroupId: string; date: string; delayableUntil: string } | undefined
  >();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Treatment plan',
      description: 'Page title for the Treatment plan page',
    }),
  );

  const msdApplyCouponsEnabled = useFeatureFlagBoolean(
    'FF_CF_APPLY_MSD_ENABLED',
  );

  const { data, loading, refetch } = useQuery<
    OfferingPlanQuery,
    OfferingPlanQueryVariables
  >(
    gql`
      ${usePurchaseCardContent.fragment}
      fragment PsgTimeline on PurchaseSyncGroup {
        id
        sequenceContexts {
          id
          sequence {
            id
            __typename
          }
        }
        timeline {
          id
          products {
            id
            displayName
            type
          }
          addonProducts {
            id
            displayName
            type
          }
          date
          status
          preDiscountTotalAmount {
            id
            baseUnitAmount
          }
          totalAmount {
            id
            baseUnitAmount
          }
        }
      }

      query OfferingPlan {
        profile {
          id
          email
          purchases {
            ...UsePurchaseCardContentPurchase
            id
            status
            problemType
            contexts {
              id
              status
              consultations {
                id
                type
                status
              }
              sequence {
                __typename
                id
                externalLinks {
                  id
                  text
                  url
                }
                products {
                  id
                  name
                  friendlyName
                  productType
                  photo {
                    id
                    url
                  }
                }
              }
            }
            offering {
              id
              status
              friendlyName
              description
              advertisedName
            }
            canBeSwitched
            syncGroups {
              id
              nextOrderDue
              nextOrder {
                id
                date
                totalAmount {
                  id
                  formattedAmount
                }
                discountAmount {
                  id
                  baseUnitAmount
                  formattedAmount
                }
                preDiscountTotalAmount {
                  id
                  formattedAmount
                }
                products {
                  id
                  displayName
                  photoUrl
                }
                discounts {
                  id
                  name
                  removable
                  amount {
                    id
                    formattedAmount
                  }
                }
              }
              status
              actions {
                id
                ... on DelayPurchaseSyncGroupAction {
                  delayableUntil
                }
              }
              sequenceContexts {
                id
                sequence {
                  id
                }
              }
              ...PsgTimeline
            }
          }
        }
      }
    `,
    {
      // This is used to ensure customers won't interact with the page
      // until we have the results of the delay mutation.
      notifyOnNetworkStatusChange: true,
    },
  );

  const [isTreatmentScheduleOpen, setIsTreatmentScheduleOpen] = useState(false);

  const purchase = data?.profile?.purchases?.find((p) => p.id === purchaseId);

  const syncGroupById = useMemo(
    () => new Map((purchase?.syncGroups ?? []).map((sg) => [sg.id, sg])),
    [purchase],
  );

  const psgTimelineProps = getOrderTimelinePropsForPsgTimeline({
    palette: palette.orderTimelineItem,
    syncGroups: purchase?.syncGroups ?? [],
    shouldShowAllItems: isTreatmentScheduleOpen,
    logger,
  });

  const nextSyncGroupOrder = (
    purchase?.syncGroups
      ?.filter((sg) =>
        sg.sequenceContexts?.find(
          (sc) => sc.sequence?.__typename === 'PrescribableSequence',
        ),
      )
      .map((sg) => ({ psgId: sg.id, ...sg.nextOrder }))
      .filter((o): o is SyncGroupOrder => !!o) ?? []
  )
    .sort((a, b) => {
      if (!a.date || !b.date || a.date === b.date) {
        return 0;
      }
      if (a.date < b.date) {
        return -1;
      }
      return 1;
    })
    .at(0);

  const form = useForm<{ couponCode: string }>({
    mode: 'onChange',
    defaultValues: { couponCode: '' },
  });
  const [showCouponInput, setShowCouponInput] = useState(false);

  const [applyCouponCodes, { loading: applyingCouponCodes }] = useMutation<
    AddCouponCodesToPurchaseSyncGroupMutation,
    AddCouponCodesToPurchaseSyncGroupMutationVariables
  >(
    gql`
      mutation AddCouponCodesToPurchaseSyncGroup(
        $input: AddCouponCodesToPurchaseSyncGroupInput!
      ) {
        addCouponCodesToPurchaseSyncGroup(input: $input) {
          purchaseSyncGroupCoupons {
            id
            code
            outcome
          }
        }
      }
    `,
    {
      context: { skipErrorNotification: true },
      onCompleted: (data) => {
        const coupons =
          data?.addCouponCodesToPurchaseSyncGroup?.purchaseSyncGroupCoupons ??
          [];
        for (const c of coupons) {
          let message: string | undefined;
          switch (c.outcome) {
            case 'EXPIRED':
              message = formatMessage(
                { defaultMessage: 'Discount code {coupon} has expired' },
                { coupon: c.code },
              );
              break;
            case 'GENERIC_FAILURE':
              message = formatMessage(
                {
                  defaultMessage: 'Discount code {coupon} could not be applied',
                },
                { coupon: c.code },
              );
              break;
            case 'NOT_FOUND':
              message = formatMessage(
                { defaultMessage: 'Discount code {coupon} not found' },
                { coupon: c.code },
              );
              break;
          }
          if (message) {
            notificationService.show({
              type: 'error',
              message,
            });
          }
        }

        const successfulCoupons = coupons.filter(
          (c) => c.outcome === 'SUCCESS',
        );
        if (
          successfulCoupons.length !==
            nextSyncGroupOrder?.discounts.filter((f) => f.removable).length ||
          !successfulCoupons.every((c) =>
            nextSyncGroupOrder.discounts.find((d) => d.name === c.code),
          )
        ) {
          refetch();
        }
      },
      onError: () => {
        notificationService.show({
          type: 'error',
          message: formatMessage({
            defaultMessage: 'Error updating coupon codes',
            description:
              'Error message when we were unable to update coupon codes on an order',
          }),
        });
      },
    },
  );

  const ordersAccordionThemeStyle = useThemeExtension<AccordionPalette>(
    'accordion.defaultStyle',
    'default',
  );

  if (loading && !data) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !purchase ||
    !purchase.contexts?.length ||
    purchase.contexts.every((c) => c.status !== 'ACTIVE')
  ) {
    return <Redirect to={profileRoute} />;
  }

  const refillNowUrl = getZendeskRequestUrl({
    params: config.weightRefillNowZendeskParams,
    email: data?.profile?.email,
  });
  const rescheduleUrl = getZendeskRequestUrl({
    params: config.weightRescheduleZendeskParams,
    email: data?.profile?.email,
  });
  const resumeNowUrl = getZendeskRequestUrl({
    params: config.weightResumeNowZendeskParams,
    email: data?.profile?.email,
  });

  const hasActiveContext = purchase.contexts.some(
    (c) =>
      c.status === 'ACTIVE' &&
      c.sequence?.products?.some((p) => ['OTC', 'RX'].includes(p.productType)),
  );

  const externalLinks = purchase.contexts.flatMap(
    (c) => c.sequence?.externalLinks ?? [],
  );

  return (
    <article className="space-y-8">
      <div className="space-y-4">
        <Typography isBold size="lg">
          <FormattedMessage defaultMessage="Your plan" />
        </Typography>

        {purchase.problemType && (
          <Card>
            <div className="flex flex-row justify-between items-center gap-2 mb-3">
              <div className="flex flex-col gap-2 flex-1">
                {purchase.canBeSwitched && (
                  <>
                    <div className="flex justify-between">
                      {purchase.offering?.advertisedName && (
                        <div className="mb-3">
                          <Typography isBold size="sm">
                            {purchase.offering.advertisedName}
                          </Typography>
                        </div>
                      )}
                      <Button
                        onClick={() => history.push(switchRoute(purchaseId))}
                        size="sm"
                        palette="alternate"
                        level="secondary"
                      >
                        <FormattedMessage defaultMessage="Change plan" />
                      </Button>
                    </div>
                    {purchase.offering?.friendlyName && (
                      <Typography size="paragraph">
                        {purchase.offering.friendlyName}
                      </Typography>
                    )}
                  </>
                )}
                {!purchase.canBeSwitched && (
                  <div className="flex justify-between">
                    {purchase.offering?.friendlyName && (
                      <div className="mb-3">
                        <Typography isBold size="sm">
                          {purchase.offering.friendlyName}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-4">
              {externalLinks.length > 0 && (
                <>
                  <Divider variant="separator" />

                  <div className="flex flex-col gap-2 pb-2">
                    {externalLinks.map((link) => (
                      <a
                        key={link.id}
                        href={link.url}
                        className="text-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography size="medium-paragraph">
                          {link.text}
                        </Typography>
                      </a>
                    ))}
                  </div>
                </>
              )}

              {hasActiveContext && psgTimelineProps.orders.length > 0 && (
                <ChakraAccordion
                  allowToggle
                  variant={ordersAccordionThemeStyle}
                  allowMultiple={true}
                >
                  <AccordionItem
                    style={{
                      borderBottom: '0',
                      paddingBottom: '0',
                    }}
                  >
                    {({ isExpanded }) => {
                      return (
                        <>
                          <Typography size="medium-paragraph" isBold>
                            <FormattedMessage defaultMessage="Order schedule" />
                          </Typography>
                          <div>
                            <Collapse
                              animateOpacity
                              startingHeight={240}
                              in={isExpanded}
                            >
                              <div className="pt-4 overflow-hidden">
                                <OrderTimeline {...psgTimelineProps} />
                              </div>
                            </Collapse>
                          </div>

                          <AccordionButton as="div" className="mt-3">
                            <div className="w-full">
                              <Button
                                level="secondary"
                                isFullWidth
                                onClick={() =>
                                  setIsTreatmentScheduleOpen(!isExpanded)
                                }
                              >
                                {isExpanded && (
                                  <FormattedMessage
                                    defaultMessage="Hide full order schedule"
                                    description="Button text to fold an according hiding some of the customers treatment schedule"
                                  />
                                )}
                                {!isExpanded && (
                                  <FormattedMessage
                                    defaultMessage="See full order schedule"
                                    description="Button text to unfold an according showing all upcoming orders for a patients treatment schedule"
                                  />
                                )}
                              </Button>
                            </div>
                          </AccordionButton>
                        </>
                      );
                    }}
                  </AccordionItem>
                </ChakraAccordion>
              )}

              {!hasActiveContext && (
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="To resume your treatment, please contact us <a>here</a>."
                    description="Call to action to contact PX if sequence context is paused"
                    values={{
                      a: (chunks) => {
                        return (
                          <a
                            className="underline cursor"
                            href={resumeNowUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </a>
                        );
                      },
                    }}
                  />
                </Typography>
              )}
            </div>
          </Card>
        )}
      </div>

      {nextSyncGroupOrder && (
        <div
          ref={(e) => {
            if (focusOnDelay && e && !initialJumpCompleted.current) {
              e.scrollIntoView();
              initialJumpCompleted.current = true;
            }
          }}
        >
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="Your next order"
              description="Title for upcoming orders on treatment plan page"
            />
          </Typography>

          <Typography isBold size="small-text">
            <FormattedMessage
              defaultMessage="Payment due: <a>{date}</a>"
              description="Label showing when an upcoming order will be processed"
              values={{
                date: formatDate(
                  mapBrandToAdaptersBrand(config.brand),
                  nextSyncGroupOrder.date,
                  {
                    dateStyle: 'medium',
                  },
                ),
                a: (chunks) => (
                  <time dateTime={nextSyncGroupOrder.date}>{chunks}</time>
                ),
              }}
            />
          </Typography>

          <div
            className={clsx(
              'bg-white overflow-auto mt-6',
              palette.nextOrder?.cardBorder,
              palette.nextOrder?.textColor,
              palette.nextOrder?.borderRadius,
            )}
          >
            <div
              className={clsx(
                'p-4 flex items-center',
                palette.nextOrder?.headerBgColor,
              )}
            >
              <Typography isBold size="xs">
                <FormattedMessage
                  defaultMessage="Your plan"
                  description="Title for an upcoming order on treatment plan page"
                />
              </Typography>
              <div className="ml-2" />
              <HiArrowPath className={palette.nextOrder?.textColor} />
            </div>
            {nextSyncGroupOrder.products && (
              <div className="space-y-4 p-5">
                {purchase.problemType === 'WEIGHT_LOSS' && (
                  <div className="flex justify-between items-center">
                    <Typography isBold size="paragraph">
                      {formatMessage(
                        {
                          defaultMessage:
                            'Weight Reset {isGb, select, true {Programme} other {Program}}{tier} with:',
                          description: 'Offering plan page',
                        },
                        {
                          isGb: getConfig().countryCode === 'GB',
                          tier:
                            purchase.canBeSwitched &&
                            purchase.offering?.advertisedName
                              ? ` (${purchase.offering.advertisedName})`
                              : '',
                        },
                      )}
                    </Typography>
                  </div>
                )}
                {nextSyncGroupOrder.products.map((product) => (
                  <div
                    key={product.id}
                    className="flex justify-between items-center"
                  >
                    <div className="flex items-center space-x-2">
                      {product.photoUrl && (
                        <img
                          src={product.photoUrl}
                          className="w-14 h-14 rounded"
                        />
                      )}
                      <Typography isBold size="medium-paragraph">
                        {product.displayName}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {nextSyncGroupOrder.discounts
              .filter((d) => d.removable)
              .map((d) => (
                <div key={d.id}>
                  <div className={palette.nextOrder?.orderTopBorder} />

                  <div className="p-5 flex justify-between items-center">
                    <Typography isBold size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="Discount code"
                        description="Title for a section showing the applied discount code"
                      />
                    </Typography>
                    {applyingCouponCodes || loading ? (
                      <LoadingSpinner />
                    ) : (
                      <div className="p-2 bg-neutral-100 rounded flex items-center text-neutral-700 relative">
                        <ShoppingTag className="mr-1" />
                        <p className="text-sm/[14px] text-neutral-700">
                          {d.name}
                        </p>
                        {msdApplyCouponsEnabled && (
                          <div className="relative ml-2">
                            <button
                              disabled={applyingCouponCodes || loading}
                              className="p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                              onClick={() => {
                                if (!applyingCouponCodes) {
                                  applyCouponCodes({
                                    variables: {
                                      input: {
                                        purchaseSyncGroupId:
                                          nextSyncGroupOrder.psgId,
                                        couponCodes: [],
                                      },
                                    },
                                  });
                                }
                              }}
                            />
                            <DeleteCrossClose />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {msdApplyCouponsEnabled &&
              nextSyncGroupOrder.discounts.filter((d) => d.removable).length ===
                0 && (
                <>
                  <div className={palette.nextOrder?.orderTopBorder} />
                  {!showCouponInput && (
                    <div className="p-5">
                      <button
                        className="flex justify-between items-center w-full"
                        onClick={() => setShowCouponInput(true)}
                      >
                        <Typography isBold size="medium-paragraph">
                          <FormattedMessage
                            defaultMessage="Add discount code"
                            description="Title for a section showing the applied discount code"
                          />
                        </Typography>
                        <AddPlus
                          width={12}
                          height={12}
                          className="fill-neutral-700"
                        />
                      </button>
                    </div>
                  )}

                  {showCouponInput && (
                    <form
                      onSubmit={form.handleSubmit(async (data) =>
                        applyCouponCodes({
                          variables: {
                            input: {
                              purchaseSyncGroupId: nextSyncGroupOrder.psgId,
                              couponCodes: [data.couponCode],
                            },
                          },
                        }),
                      )}
                      className="p-5 flex justify-between items-center"
                    >
                      <TextInput
                        autoFocus={true}
                        ref={form.register({
                          required: true,
                          maxLength: {
                            value: 100,
                            message: formatMessage({
                              defaultMessage:
                                'Discount code must be less than 100 characters',
                              description:
                                'Upcoming order page discount code validation max length message',
                            }),
                          },
                        })}
                        name="couponCode"
                        label={formatMessage({
                          defaultMessage: 'Discount code',
                          description:
                            'Label for next order coupon code input on plan page',
                        })}
                      />
                      <div className="self-end ml-2 mb-1">
                        <Button
                          isSubmit
                          level="secondary"
                          isDisabled={!form.formState.isValid}
                          isLoading={form.formState.isSubmitting || loading}
                        >
                          <FormattedMessage
                            defaultMessage="Apply"
                            description="Button on a form to apply the entered coupon code to an order"
                          />
                        </Button>
                      </div>
                    </form>
                  )}
                </>
              )}

            {nextSyncGroupOrder.discountAmount.baseUnitAmount > 0 && (
              <>
                <div className={palette.nextOrder?.orderTopBorder} />

                <div className="p-5 space-y-2">
                  {nextSyncGroupOrder.discounts.map((d) => (
                    <div key={d.id} className="flex justify-between">
                      <Typography size="medium-paragraph">
                        <FormattedMessage
                          defaultMessage="{removable, select, true {Discount} other {{name}}} applied"
                          description="Title amount for the discount amount applied to this order"
                          values={{ removable: d.removable, name: d.name }}
                        />
                      </Typography>
                      <Typography size="medium-paragraph">
                        <FormattedMessage
                          defaultMessage="- {amount}"
                          values={{ amount: d.amount.formattedAmount }}
                          description="discount amount applied to order"
                        />
                      </Typography>
                    </div>
                  ))}

                  <div className="flex justify-between">
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="Subtotal"
                        description="Title amount for the order price before discounts are removed"
                      />
                    </Typography>
                    <Typography size="medium-paragraph">
                      {
                        nextSyncGroupOrder.preDiscountTotalAmount
                          .formattedAmount
                      }
                    </Typography>
                  </div>
                </div>
              </>
            )}

            <div
              className={clsx(
                'p-5 text-neutral-700',
                palette.nextOrder?.totalBgColor,
              )}
            >
              <div className="flex justify-between mb-2">
                <Typography isBold size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="Total"
                    description="Title for amount due in upcoming order"
                  />
                </Typography>
                <Typography isBold size="medium-paragraph">
                  {nextSyncGroupOrder.totalAmount.formattedAmount}
                </Typography>
              </div>

              <Typography size="paragraph" color="text-neutral-700">
                <FormattedMessage
                  defaultMessage="This will automatically be deducted from your default payment method on {date}."
                  values={{
                    date: formatDate(
                      mapBrandToAdaptersBrand(config.brand),
                      nextSyncGroupOrder.date,
                      {
                        dateStyle: 'short',
                      },
                    ),
                  }}
                  description="Explanation of when amount will be charged"
                />
              </Typography>
            </div>
          </div>

          <div className="mt-10">
            <Typography isBold size="large-paragraph">
              <FormattedMessage
                defaultMessage="Manage order"
                description="Title of a section with buttons to reschedule orders"
              />
            </Typography>
          </div>

          <div
            className={clsx(
              'bg-white overflow-auto mt-3',
              palette.nextOrder?.manageOrderTextColor,
              palette.nextOrder?.cardBorder,
              palette.nextOrder?.borderRadius,
            )}
          >
            {!syncGroupById
              .get(nextSyncGroupOrder.psgId)
              ?.actions?.find(
                (action) =>
                  action.__typename === 'DelayPurchaseSyncGroupAction',
              ) && (
              <>
                <a
                  className="cursor font-medium p-4 flex items-center justify-between"
                  href={rescheduleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex items-center">
                    <ClockForward className="mr-3 fill-current" />
                    <Typography
                      isBold
                      size="paragraph"
                      color="text-neutral-700"
                    >
                      <FormattedMessage
                        defaultMessage="Delay order"
                        description="Button text for requesting to delay an upcoming order"
                      />
                    </Typography>
                  </div>
                  <ArrowLink />
                </a>
                <div
                  className={clsx('w-full', palette.nextOrder?.orderTopBorder)}
                />
              </>
            )}

            {syncGroupById
              .get(nextSyncGroupOrder.psgId)
              ?.actions?.find(
                (action) =>
                  action.__typename === 'DelayPurchaseSyncGroupAction',
              ) && (
              <>
                <button
                  className="w-full p-4"
                  onClick={() => {
                    const delayableUntil = syncGroupById
                      .get(nextSyncGroupOrder.psgId)
                      ?.actions?.find(
                        (action) =>
                          action.__typename === 'DelayPurchaseSyncGroupAction',
                      )?.delayableUntil;
                    if (!delayableUntil) {
                      throw new Error('expected delayable until to be set');
                    }
                    setUpcomingOrderToDelay({
                      syncGroupId: nextSyncGroupOrder.psgId,
                      date: nextSyncGroupOrder.date,
                      delayableUntil,
                    });
                  }}
                >
                  <div className="flex items-center font-medium justify-between">
                    <div className="flex items-center">
                      <ClockForward className="mr-3 fill-current" />
                      <Typography
                        isBold
                        size="paragraph"
                        color="text-neutral-700"
                      >
                        <FormattedMessage
                          defaultMessage="Delay order"
                          description="Button text for delaying an upcoming order"
                        />
                      </Typography>
                    </div>
                    <ChevronRight />
                  </div>
                </button>
                <div className="w-full border-t" />
              </>
            )}

            <a
              className="cursor font-medium p-4 flex items-center justify-between"
              href={refillNowUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center">
                <InfoCircle className="fill-current mr-3" />
                <Typography isBold size="paragraph" color="text-neutral-700">
                  <FormattedMessage
                    defaultMessage="Request early order"
                    description="Body for requesting an order be refilled immediately"
                  />
                </Typography>
              </div>
              <ArrowLink />
            </a>
          </div>
        </div>
      )}

      {!!upcomingOrderToDelay && (
        <DelayModal
          onClose={() => setUpcomingOrderToDelay(undefined)}
          upcomingOrder={upcomingOrderToDelay}
          onDelaySuccess={refetch}
          onDelayError={refetch}
          brandColor={{
            noteBgColor: palette.nextOrder?.modalNoteBgColor,
          }}
        />
      )}
    </article>
  );
}
