import React, { useCallback, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { Dropdown, TextInput, Typography } from '@eucalyptusvc/design-system';
import { useRequiredValidation, useScript } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import './styles.css';

import type {
  UseFormMethods,
  FieldErrors,
  RegisterOptions,
} from 'react-hook-form';
import { AddressFields } from './types';

type AddressInputProps = {
  className?: string;
  name: string;
  registerField: UseFormMethods<AddressFields>['register'];
  errors?: FieldErrors<AddressFields>;
  useAutoComplete?: boolean;
  palette?: 'default' | 'alternate' | 'white';
  showSimplifiedForm?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange?: (address: Partial<AddressFields>) => void;
};

// https://developers.google.com/maps/documentation/javascript/url-params#required_parameters
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.googleCallback = () => {};

const rowClasses = 'flex flex-col md:flex-row md:mb-3 mt-0 md:space-x-3';
const columnSubClasses = 'w-full mb-3 md:mb-0';

const messages = defineMessages({
  addressLabel: {
    defaultMessage: 'Address',
    description: 'Label for address field to enter address',
  },
  address1Label: {
    defaultMessage: 'Address line 1',
    description: 'Label for address line 1 field in address form',
  },
  address2Label: {
    defaultMessage: 'Address line 2',
    description: 'Label for address line 2 field in address form',
  },
  cityLabel: {
    defaultMessage: 'Town or City',
    description: 'Label for town or city field in address form',
  },
  postCodeLabel: {
    defaultMessage: 'Postcode',
    description: 'Label for postcode field in address form',
  },
  countryLabel: {
    defaultMessage: 'Country',
    description: 'Label for country field in address form',
  },
  countyLabel: {
    defaultMessage: 'County',
    description: 'Label for county field in address form',
  },
  stateLabel: {
    defaultMessage: 'State',
    description: 'Label for state field in address form',
  },
  prefectureLabel: {
    defaultMessage: 'Prefecture',
    description: 'Label for prefecture field in address form',
  },
  municipalityLabel: {
    defaultMessage: 'Municipality',
    description: 'Label for municipality field in address form',
  },
  townAreaLabel: {
    defaultMessage: 'Town Area',
    description: 'Label for town area field in address form',
  },
  townAddressUnchangedError: {
    defaultMessage:
      'Please ensure you have added a street address, building name and room number',
    description:
      'Error message when the patient has not updated the value given by the Google autosuggest to include extra address details',
  },
});

const AU_STATE_OPTIONS = [
  { label: '', value: '' },
  { label: 'Australian Capital Territory', value: 'ACT' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Northern Territory', value: 'NT' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
  { label: 'Victoria', value: 'VIC' },
  { label: 'Western Australia', value: 'WA' },
];

export const IntlAddressInput = ({
  className,
  registerField,
  errors,
  name,
  palette,
  useAutoComplete = false,
  showSimplifiedForm = false,
  autoFocus = false,
  onChange,
}: AddressInputProps): React.ReactElement => {
  const config = getConfig();
  const environment = useEnvironment();
  const { formatMessage } = useIntl();
  const [noStreetNum, setNoStreetNum] = useState(false);
  const [showJapanInputInstructions, setShowJapanInputInstructions] =
    useState(false);
  const [townAreaAutocompleteValue, setTownAreaAutoCompleteValue] = useState<
    string | null
  >(null);

  const googlePlacesUrl = `https://maps.googleapis.com/maps/api/js?key=${environment.googlePlacesApiKey}&libraries=places&callback=googleCallback&language=${config.countryCode}`;

  // eslint-disable-next-line
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const line1Ref = useRef<HTMLInputElement | null>();
  const line2Ref = useRef<HTMLInputElement | null>();
  const suburbRef = useRef<HTMLInputElement | null>();
  const stateRef = useRef<HTMLInputElement | HTMLSelectElement | null>();
  const postcodeRef = useRef<HTMLInputElement | null>();
  const prefectureRef = useRef<HTMLInputElement | null>();
  const municipalityRef = useRef<HTMLInputElement | null>();
  const townAreaRef = useRef<HTMLInputElement | null>();

  const hideExtraFields = showSimplifiedForm;

  const onAddressChange = React.useCallback(() => {
    onChange?.({
      line1: line1Ref.current?.value,
      line2: line2Ref.current?.value,
      city: suburbRef.current?.value,
      postalCode: postcodeRef.current?.value,
      country: config.country,
      state: stateRef.current?.value,
      prefecture: prefectureRef.current?.value,
      municipality: municipalityRef.current?.value,
      townArea: townAreaRef.current?.value,
    });

    // clears the street number error message after manual text input
    if (
      noStreetNum &&
      line1Ref.current?.value &&
      /\d/.test(line1Ref.current.value)
    ) {
      setNoStreetNum(false);
    }
  }, [config.country, onChange, noStreetNum]);

  const fillAddress = useCallback((): void => {
    if (!autoCompleteRef.current) {
      return;
    }

    const place = autoCompleteRef.current.getPlace();

    if (config.countryCode === 'JP') {
      if (
        !postcodeRef.current ||
        !prefectureRef.current ||
        !municipalityRef.current ||
        !townAreaRef.current
      ) {
        setShowJapanInputInstructions(false);
        return;
      }

      // Japan-specific: Example full address -> 〒100-8994東京都千代田区丸ノ内二丁目7番2号 (2 Chome-7-2 Marunouchi, Chiyoda City, Tokyo 100-8994, Japan)
      let postalTown = '';
      let sublocalityLevelOne = '';

      // eslint-disable-next-line
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            postcodeRef.current.value = component.long_name;
            break;
          case 'locality':
          case 'postal_town':
            postalTown = component.short_name;
            break;
          case 'administrative_area_level_1':
            prefectureRef.current.value = component.short_name;
            break;
          case 'sublocality_level_1':
            sublocalityLevelOne = component.short_name;
            break;
          case 'sublocality_level_2':
            townAreaRef.current.value = component.short_name;
            setTownAreaAutoCompleteValue(townAreaRef.current.value);
            break;
        }

        municipalityRef.current.value = `${postalTown}${sublocalityLevelOne}`;
        setShowJapanInputInstructions(true);
      }
    } else {
      if (
        !line1Ref.current ||
        !line2Ref.current ||
        !suburbRef.current ||
        !(config.addressConfig.showStateInput ? !!stateRef.current : true) ||
        !postcodeRef.current
      ) {
        return;
      }

      let streetNumber = '';
      let street = '';
      let subpremise = '';

      setNoStreetNum(false);

      const hasStreetNumber = place.address_components?.some((c) =>
        c.types.includes('street_number'),
      );

      if (!hasStreetNumber) {
        line1Ref.current.value = '';
        setNoStreetNum(true);
        return;
      }
      // eslint-disable-next-line
      for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'subpremise':
            subpremise = component.short_name;
            break;

          case 'street_number':
            streetNumber = component.long_name.replaceAll(' ', '');
            break;

          case 'route':
            street = component.long_name;
            break;

          case 'postal_code':
            postcodeRef.current.value = component.long_name;
            break;

          case 'locality':
          case 'postal_town':
            suburbRef.current.value = component.long_name;
            break;
          // county for uk
          case 'administrative_area_level_2':
            if (config.countryCode === 'GB' && stateRef.current) {
              stateRef.current.value = component.short_name;
            }
            break;

          // state for de & au, administrative_area_level_1 is "country" for uk e.g. england, scotland etc
          case 'administrative_area_level_1':
            if (config.countryCode !== 'GB' && stateRef.current) {
              stateRef.current.value = component.short_name;
            }
            break;
        }
      }

      const address1 = config.addressConfig.numberBeforeStreet
        ? `${streetNumber} ${street}`
        : `${street} ${streetNumber}`;

      line1Ref.current.value = subpremise ? subpremise : address1;
      line2Ref.current.value = subpremise ? address1 : '';
    }

    onAddressChange();
  }, [
    config.addressConfig.numberBeforeStreet,
    config.addressConfig.showStateInput,
    config.countryCode,
    onAddressChange,
  ]);

  const { loading: isScriptLoading } = useScript(googlePlacesUrl, false);

  useEffect(() => {
    if (
      !isScriptLoading &&
      useAutoComplete &&
      window.google &&
      (line1Ref.current || postcodeRef.current)
    ) {
      switch (config.countryCode) {
        case 'JP':
          if (postcodeRef.current) {
            autoCompleteRef.current =
              new window.google.maps.places.Autocomplete(postcodeRef.current, {
                componentRestrictions: { country: config.countryCode },
                fields: ['address_components'],
              });
          }
          break;
        default:
          if (line1Ref.current) {
            autoCompleteRef.current =
              new window.google.maps.places.Autocomplete(line1Ref.current, {
                componentRestrictions: { country: config.countryCode },
                fields: ['address_components'],
              });
          }
      }
    }
  }, [useAutoComplete, isScriptLoading, config.countryCode]);

  useEffect(() => {
    const l = autoCompleteRef.current?.addListener(
      'place_changed',
      fillAddress,
    );

    return () => l?.remove();
  });

  const addressLabel = hideExtraFields
    ? messages.addressLabel
    : messages.address1Label;

  const stateLabel =
    config.countryCode === 'GB' ? messages.countyLabel : messages.stateLabel;

  const addressValidation = useRequiredValidation(formatMessage(addressLabel));
  const cityValidation = useRequiredValidation(
    formatMessage(messages.cityLabel),
  );
  const stateValidation = useRequiredValidation(formatMessage(stateLabel));
  const postcodeValidation = useRequiredValidation(
    formatMessage(messages.postCodeLabel),
  );
  const countryValidation = useRequiredValidation(
    formatMessage(messages.countryLabel),
  );
  const prefectureValidation = useRequiredValidation(
    formatMessage(messages.prefectureLabel),
  );
  const municipalityValidation = useRequiredValidation(
    formatMessage(messages.municipalityLabel),
  );

  const townAreaValidation: RegisterOptions = {
    ...useRequiredValidation(formatMessage(messages.townAreaLabel)),
    pattern: townAreaAutocompleteValue
      ? {
          value: new RegExp(`^(?!${townAreaAutocompleteValue}$).*`), // regex to check the value doesn't only contain the autocomplete response
          message: formatMessage(messages.townAddressUnchangedError),
        }
      : undefined,
  };

  switch (config.countryCode) {
    case 'JP':
      return (
        <div className={className}>
          <div className={rowClasses}>
            <TextInput
              ref={(ref) => {
                postcodeRef.current = ref;
                registerField(postcodeValidation)(ref);
              }}
              label={formatMessage(messages.postCodeLabel)}
              placeholder={formatMessage(messages.postCodeLabel)}
              name={`${name}.postalCode`}
              errorMessage={errors?.postalCode?.message}
              palette={palette}
              onChange={onAddressChange}
            />
          </div>
          <div className={rowClasses}>
            <div className={columnSubClasses}>
              <TextInput
                ref={(ref) => {
                  prefectureRef.current = ref;
                  return registerField(prefectureValidation)(ref);
                }}
                label={formatMessage(messages.prefectureLabel)}
                placeholder={formatMessage(messages.prefectureLabel)}
                name={`${name}.prefecture`}
                palette={palette}
                onChange={onAddressChange}
                autoComplete="chrome-off"
                errorMessage={errors?.prefecture?.message}
              />
            </div>
          </div>
          <div className={rowClasses}>
            <div className={columnSubClasses}>
              <TextInput
                ref={(ref) => {
                  municipalityRef.current = ref;
                  return registerField(municipalityValidation)(ref);
                }}
                label={formatMessage(messages.municipalityLabel)}
                placeholder={formatMessage(messages.municipalityLabel)}
                name={`${name}.municipality`}
                palette={palette}
                onChange={onAddressChange}
                errorMessage={errors?.municipality?.message}
              />
            </div>
          </div>
          <div className={rowClasses}>
            <div className={columnSubClasses}>
              <TextInput
                ref={(ref) => {
                  townAreaRef.current = ref;
                  return registerField(townAreaValidation)(ref);
                }}
                label={formatMessage(messages.townAreaLabel)}
                placeholder={formatMessage(messages.townAreaLabel)}
                name={`${name}.townArea`}
                palette={palette}
                onChange={onAddressChange}
                errorMessage={errors?.townArea?.message}
              />
            </div>
          </div>
          <div className={rowClasses}>
            <div className={clsx(columnSubClasses, 'md:w-1/2')}>
              <TextInput
                ref={registerField(countryValidation)}
                label={formatMessage(messages.countryLabel)}
                name={`${name}.country`}
                errorMessage={errors?.country?.message}
                palette={palette}
                disabled
              />
            </div>
          </div>
          {showJapanInputInstructions && (
            <div className="bg-status-error-100 p-4 rounded border border-status-error-500 mt-6">
              <Typography size="medium-paragraph" inheritColor>
                <FormattedMessage
                  defaultMessage="Please enter information such as street address, building name and room number in the Town Area field. If you do not have a street address enter 'No street address'."
                  description="Additional address information label"
                />
              </Typography>
            </div>
          )}
        </div>
      );
    default:
      return (
        <div className={className}>
          <div className={rowClasses}>
            <div className={columnSubClasses}>
              <TextInput
                ref={(ref) => {
                  line1Ref.current = ref;
                  registerField(addressValidation)(ref);
                }}
                label={formatMessage(addressLabel)}
                placeholder={formatMessage(addressLabel)}
                name={`${name}.line1`}
                errorMessage={
                  errors?.line1?.message ||
                  (noStreetNum
                    ? formatMessage({
                        defaultMessage:
                          'Please select an address with a street number',
                      })
                    : '')
                }
                palette={palette}
                autoComplete="chrome-off"
                onChange={onAddressChange}
                autoFocus={autoFocus}
              />
            </div>
          </div>
          <div className={clsx(hideExtraFields && 'hidden')}>
            <div className={rowClasses}>
              <div className={columnSubClasses}>
                <TextInput
                  ref={(ref) => {
                    line2Ref.current = ref;
                    return registerField(ref);
                  }}
                  label={formatMessage(messages.address2Label)}
                  placeholder={formatMessage(messages.address2Label)}
                  name={`${name}.line2`}
                  palette={palette}
                  onChange={onAddressChange}
                />
              </div>
            </div>
            <div className={rowClasses}>
              <div
                className={clsx(
                  columnSubClasses,
                  config.addressConfig.showStateInput && 'md:w-1/2',
                )}
              >
                <TextInput
                  ref={(ref) => {
                    suburbRef.current = ref;
                    registerField(cityValidation)(ref);
                  }}
                  label={formatMessage(messages.cityLabel)}
                  placeholder={formatMessage(messages.cityLabel)}
                  name={`${name}.city`}
                  errorMessage={errors?.city?.message}
                  palette={palette}
                  onChange={onAddressChange}
                />
              </div>
              {config.addressConfig.showStateInput && (
                <div className={clsx(columnSubClasses, 'md:w-1/2')}>
                  {config.countryCode === 'AU' ? (
                    <Dropdown
                      ref={(ref): void => {
                        stateRef.current = ref;
                        registerField(stateValidation)(ref);
                      }}
                      label="State"
                      name={`${name}.state`}
                      errorMessage={errors?.state?.message}
                      palette={palette}
                      options={AU_STATE_OPTIONS}
                      onChange={onAddressChange}
                    />
                  ) : (
                    <TextInput
                      ref={(ref): void => {
                        stateRef.current = ref;
                        registerField(stateValidation)(ref);
                      }}
                      label={formatMessage(stateLabel)}
                      placeholder={formatMessage(stateLabel)}
                      name={`${name}.state`}
                      errorMessage={errors?.state?.message}
                      palette={palette}
                      onChange={onAddressChange}
                    />
                  )}
                </div>
              )}
            </div>
            <div className={rowClasses}>
              <div className={clsx(columnSubClasses, 'md:w-1/2')}>
                <TextInput
                  ref={(ref) => {
                    postcodeRef.current = ref;
                    registerField(postcodeValidation)(ref);
                  }}
                  label={formatMessage(messages.postCodeLabel)}
                  placeholder={formatMessage(messages.postCodeLabel)}
                  name={`${name}.postalCode`}
                  errorMessage={errors?.postalCode?.message}
                  palette={palette}
                  onChange={onAddressChange}
                />
              </div>
              <div className={clsx(columnSubClasses, 'md:w-1/2')}>
                <TextInput
                  ref={registerField(countryValidation)}
                  label={formatMessage(messages.countryLabel)}
                  name={`${name}.country`}
                  errorMessage={errors?.country?.message}
                  palette={palette}
                  disabled
                />
              </div>
            </div>
            <div className={rowClasses}>
              <div className={clsx(columnSubClasses, 'md:w-1/2')}>
                <TextInput
                  ref={registerField}
                  label={formatMessage({ defaultMessage: 'Company' })}
                  name={`${name}.company`}
                  errorMessage={errors?.company?.message}
                />
              </div>
              <div className={clsx(columnSubClasses, 'md:w-1/2')}>
                <TextInput
                  ref={registerField}
                  label={formatMessage({ defaultMessage: 'Building' })}
                  name={`${name}.building`}
                  errorMessage={errors?.building?.message}
                />
              </div>
            </div>
          </div>
        </div>
      );
  }
};
