import { ComponentStyleConfig } from '@chakra-ui/react';
import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';
import { sharedColors } from '../palette';

interface BorderStates {
  default: string;
  focus: string;
  active: string;
}

interface IconStates {
  checked: string;
  hover: string;
}

interface OptionThemeConfig {
  colors: {
    textColor: string;
    focusColor: string;
    border: BorderStates;
    background: string;
    iconBorder: BorderStates;
    iconBackground: IconStates;
  };
  boxShadowWidth: string;
}

const getCheckboxStyles = (
  config: OptionThemeConfig,
): ComponentStyleConfig => ({
  baseStyle: ({ colorScheme }) => {
    const isDefault = colorScheme === 'default';

    return {
      container: {
        border: 'none',
        width: '100%',
        _disabled: {
          opacity: 0.5,
        },
        _hover: {
          '.chakra-checkbox__control': {
            background: config.colors.iconBackground.hover,
          },
        },
        _checked: {
          _hover: {
            '.chakra-checkbox__control': {
              background: isDefault
                ? config.colors.iconBackground.checked
                : 'white',
            },
          },
        },
      },
      label: {
        width: '100%',
        color: isDefault ? config.colors.textColor : 'white',
      },
      control: {
        mt: '2px',
        bg: 'white',
        border: '1px solid',
        borderRadius: '4px',
        alignSelf: 'flex-start',
        borderColor: config.colors.iconBorder.default,
        _checked: {
          bg: isDefault ? config.colors.iconBackground.checked : 'white',
          borderColor: config.colors.iconBorder.active,
          _disabled: {
            bg: isDefault ? config.colors.iconBackground.checked : 'white',
          },
        },
        _focus: {
          borderColor: config.colors.iconBorder.focus,
          boxShadow: `0 0 0 0.188rem ${config.colors.focusColor}`,
        },
        _disabled: {
          opacity: 0.5,
        },
        'svg path': {
          stroke: isDefault ? 'white' : config.colors.iconBackground.checked,
        },
      },
    };
  },
  variants: getOptionVariants(config),
  sizes: {
    lg: {
      control: { w: '20px', h: '20px' },
    },
  },
});

const getRadioStyles = (config: OptionThemeConfig): ComponentStyleConfig => ({
  baseStyle: ({ colorScheme }) => ({
    container: {
      border: 'none',
      width: '100%',
      _disabled: {
        opacity: 0.5,
      },
      _hover: {
        '.chakra-radio__control': {
          background: config.colors.iconBackground.hover,
        },
      },
    },
    label: {
      color: colorScheme === 'default' ? config.colors.textColor : 'white',
    },
    icon: {
      stroke: 'white',
    },
    control: {
      mt: '2px',
      bg: 'white',
      border: '1px solid',
      borderRadius: '50%',
      alignSelf: 'flex-start',
      borderColor: config.colors.iconBorder.default,
      _checked: {
        borderColor: config.colors.iconBorder.active,
        _before: {
          content: `""`,
          display: 'inline-block',
          pos: 'relative',
          w: '12px',
          h: '12px',
          borderRadius: '50%',
          bg: config.colors.iconBackground.checked,
        },
      },
      _focus: {
        borderColor: config.colors.iconBorder.focus,
        boxShadow: `0 0 0 0.188rem ${config.colors.focusColor}`,
      },
      _hover: {
        bg: config.colors.iconBackground.hover,
      },
      _disabled: {
        opacity: 0.5,
      },
    },
  }),
  variants: getOptionVariants(config, true),
  sizes: {
    lg: {
      control: { w: '20px', h: '20px' },
    },
  },
});

const getOptionVariants = (
  config: OptionThemeConfig,
  isRadio = false,
): Record<string, SystemStyleInterpolation> => ({
  error: {
    control: {
      bg: sharedColors.status.error[100],
      borderColor: sharedColors.status.error[500],
    },
  },
  active: {
    container: {
      _hover: 'none',
    },
  },
  box: {
    container: {
      bg: 'white',
      borderColor: config.colors.border.default,
      border: '1px solid',
      borderRadius: 8,
      _focusWithin: {
        borderColor: config.colors.border.focus,
      },
    },
    label: {
      color: config.colors.textColor,
    },
    control: {
      _focus: { boxShadow: `0 0 0 0.188rem ${config.colors.focusColor}` },
    },
  },
  'box-active': {
    container: {
      p: 12,
      borderColor: config.colors.border.active,
      border: '1px solid',
      borderRadius: '8px',
      bg: config.colors.background,
      boxShadow: `0 0 0 ${config.boxShadowWidth} ${config.colors.border.focus}`,
      _hover: 'none',
      _focusWithin: {
        borderColor: config.colors.border.focus,
      },
    },
    label: {
      color: config.colors.textColor,
    },
    control: {
      _focus: { boxShadow: `0 0 0 0.188rem ${config.colors.focusColor}` },
      _checked: {
        bg: isRadio ? 'white' : config.colors.iconBackground.checked,
      },
      'svg path': {
        stroke: 'white',
      },
    },
  },
  'box-error': {
    container: {
      p: 12,
      mb: 3,
      bg: 'white',
      borderColor: sharedColors.status.error[500],
      border: '1px solid',
      borderRadius: '8px',
    },
    control: {
      bg: sharedColors.status.error[100],
      borderColor: sharedColors.status.error[500],
      _invalid: {
        borderColor: sharedColors.status.error[500],
      },
    },
  },
});

export const getOptionComponents = (
  config: OptionThemeConfig,
): Record<string, ComponentStyleConfig> => ({
  Checkbox: getCheckboxStyles(config),
  Radio: getRadioStyles(config),
});
