import { getConfig } from '@customer-frontend/config';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { Button, Typography } from '@eucalyptusvc/design-system';
import clsx from 'clsx';
import React, { VFC } from 'react';

type CompleteQuizTemplateProps = {
  image?: React.ReactElement;
  centerContent?: boolean;
  title: string;
  description?: string;
  button: {
    buttonAction: () => void;
    buttonText: string;
  };
};

export const CompleteQuizTemplate: VFC<CompleteQuizTemplateProps> = ({
  image,
  title,
  description,
  button,
  centerContent,
}) => {
  const { brand } = getConfig();
  const imageClassName = clsx({ 'flex justify-center': !!centerContent });

  return (
    <div className="flex flex-col space-y-8 mb-12">
      {image && <div className={imageClassName}>{image}</div>}
      <Typography
        size="xl"
        isBold
        textAlign={centerContent ? 'center' : 'left'}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          size="medium-paragraph"
          textAlign={centerContent ? 'center' : 'left'}
        >
          {description}
        </Typography>
      )}
      <Button
        onClick={button.buttonAction}
        isFullWidth
        palette={getPrimaryButtonPalette(brand)}
      >
        {button.buttonText}
      </Button>
    </div>
  );
};
