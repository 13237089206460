import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { useEffect, useState } from 'react';

export const useRefillCooldown = (
  cooldownActiveUntil?: string | null | undefined,
): {
  isCooldownActive: boolean;
  cooldownDaysAndHours?: {
    days: number;
    hours: number;
  };
} => {
  const featureFlagClient = useFeatureFlagClient();
  const enableTreatmentRefillCooldown = featureFlagClient.getBoolean(
    'ENABLE_TREATMENT_REFILL_COOLDOWN',
  );

  const [isCooldownActive, setIsCooldownActive] = useState(
    cooldownActiveUntil && new Date(cooldownActiveUntil).getTime() > Date.now(),
  );

  useEffect(() => {
    if (cooldownActiveUntil) {
      setIsCooldownActive(new Date(cooldownActiveUntil).getTime() > Date.now());
    }
  }, [cooldownActiveUntil]);

  if (!enableTreatmentRefillCooldown) {
    return {
      isCooldownActive: false,
    };
  }

  if (!isCooldownActive) {
    return {
      isCooldownActive: false,
    };
  }

  return {
    isCooldownActive,
  };
};
