import { createRum } from '@customer-frontend/real-user-monitoring';
import { getConfig } from '@customer-frontend/config';
import { environment } from 'utils/environment';

const config = getConfig();

createRum({
  apiUrl: environment.apiUrl,
  appEnv: environment.appEnv,
  brand: config.brand,
  datadogRumApplicationId: environment.datadogRumApplicationId,
  datadogRumToken: environment.datadogRumToken,
});
