import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Typography, Divider } from '@eucalyptusvc/design-system';
import {
  MedicareCollectionForm,
  MedicareEnabled,
  MedicareFormOnPage,
} from '@customer-frontend/healthcare-collection';
import {
  ConsultationStage,
  ProblemType,
} from '@customer-frontend/graphql-types';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { FormattedMessage } from 'react-intl';

export const ConsultationPayConfirm = ({
  backToProfilePath,
  consultation,
  profile,
}: {
  backToProfilePath: string;
  consultation?: {
    stage: ConsultationStage;
    type: ProblemType;
    isUpfrontPayment: boolean;
  } | null;
  profile?: {
    email: string;
    shortAddressableName?: string | null;
    phone?: string | null;
  } | null;
}): ReactElement => {
  const history = useHistory();
  const config = getConfig();

  const onBackToProfile = (): void => history.push(backToProfilePath);

  const getPrimaryContent = (): React.ReactElement => {
    return (
      <Card>
        <div className="flex items-center space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              style={{ fill: '#37ad4d' }}
              d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
            />
          </svg>
          <Typography size="lg" isBold>
            <FormattedMessage
              defaultMessage="Consultation confirmed"
              description="title text describing that the consultation has been confirmed"
            />
          </Typography>
        </div>
        <Divider my="md" />
        <div className="flex flex-col space-y-5">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Thank you{isInitial, select, true { for your payment} other {}}, a {isGb, select, true {prescriber} other {practitioner}} will now review your information."
              description="Text after the customer has paid"
              values={{
                isInitial: consultation?.stage === 'INITIAL',
                isGb: config.countryCode === 'GB',
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage=" They will be in touch via email with any follow-up questions. You can expect a response within 24 hours (Monday-Friday)."
              description="Text after the customer has paid describing the next steps"
            />
          </Typography>
          {consultation?.stage !== 'FOLLOW_UP' && (
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="We have sent a receipt to <strong>{email}</strong>"
                description="Text after the customer has paid describing where their email has been sent"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  email: profile?.email,
                }}
              />
            </Typography>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="max-w-screen-sm py-8 md:py-4 px-4 mx-auto space-y-6">
      {getPrimaryContent()}
      <MedicareEnabled problemType={consultation?.type}>
        <MedicareCollectionForm
          validationLevel="all"
          submitButtonText="Save"
          onPage={MedicareFormOnPage.consultPaid}
          problemType={consultation?.type}
        />
      </MedicareEnabled>
      <div>
        <Button
          level="secondary"
          palette={getSecondaryButtonPalette(config.brand)}
          onClick={onBackToProfile}
          isFullWidth
        >
          <FormattedMessage
            defaultMessage=" &larr; Back to profile"
            description="button text after paying to return them back to profile"
          />
        </Button>
      </div>
    </div>
  );
};
