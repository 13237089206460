import React from 'react';
import { gql } from '@apollo/client';
import {
  ConsultationStatus,
  ProblemType,
  UseProfileCardsConsultationFragment,
  UseProfileCardsPurchaseFragment,
  UseProfileCardsQuizApplicationFragment,
} from '@customer-frontend/graphql-types';
import type {
  ProfileConsultationCard,
  ProfileQuizCard,
  ProfilePurchaseCard,
  ProfileCard,
} from './types';
import { takeTheLatestByProblemType } from './takeTheLatestByProblemType';

const completedConsultationStatuses: ConsultationStatus[] = [
  'CUSTOMER_COMPLETED',
  'ORDER_COMPLETED',
];

/**
 * Joins completed consults with INITIAL quizzes without a consult (of any status) by ProblemType.
 * @returns a list of Quizzes or Consults the patient is currently undertaking.
 */
export function useProfileCards<
  Q extends UseProfileCardsQuizApplicationFragment,
  C extends UseProfileCardsConsultationFragment,
  P extends UseProfileCardsPurchaseFragment,
>(data?: {
  profile?: {
    consultations: C[];
    initialQuizApplications?: Q[] | null;
    archivedQuizApplications?: Q[] | null;
    purchases?: P[] | null;
  } | null;
}): ProfileCard<Q, C, P>[] {
  return React.useMemo(() => {
    const consultations = takeTheLatestByProblemType(
      data?.profile?.consultations ?? [],
      (it) => it.type,
    );

    // Only get the latest initial quiz application by problem type
    // Since we are allowing a rejected customer to have an initial
    // consult again, it is possible for a customer to have more than
    // one initial quiz applications under the same problem type
    const initialApplications = data?.profile?.initialQuizApplications ?? [];
    const archivedInitialApplications =
      data?.profile?.archivedQuizApplications ?? [];
    const initialQuizApplications = takeTheLatestByProblemType(
      [...initialApplications, ...archivedInitialApplications],
      (it) => it.problemType,
    );

    const visiblePurchases = data?.profile?.purchases?.filter(
      (p) => p.isVisibleInProfilePage,
    );

    const consultationIdsWithPurchases = visiblePurchases
      ?.flatMap((purchase) => purchase.contexts)
      .flatMap((context) => context?.consultations)
      .map((consultation) => consultation?.id);

    const quizApplicationsWithoutConsultations = initialQuizApplications.filter(
      (quizApplication) => {
        const consult = consultations.find(
          (con) => con.type === quizApplication.problemType,
        );
        if (consult) {
          if (consult.quizApplication?.id === quizApplication.id) {
            return false;
          }
          if (
            `${consult.createdAt}`.localeCompare(
              `${quizApplication.createdAt}`,
            ) >= 0
          ) {
            return false;
          }
        }
        return true;
      },
    );

    const problemTypeToReviewConsultationMap = consultations.reduce(
      (ans, c) => {
        if (
          c.stage === 'REVIEW' &&
          !completedConsultationStatuses.includes(c.status)
        ) {
          ans.set(c.type, c);
        }
        return ans;
      },
      new Map<ProblemType, C>(),
    );

    // Hide consultations that have been completed and treatment finished
    const visibleConsultations = consultations.filter((consultation) => {
      if (
        consultation.typeIsFlexi &&
        consultation.status === 'DOCTOR_COMPLETED' &&
        consultation.isApproved &&
        !consultation.purchasePrompt
      ) {
        // The doctor has likely represcribed the same sequence. There is
        // nothing actionable from a user perspective.
        return false;
      }

      if (
        quizApplicationsWithoutConsultations.some(
          (quizApp) => quizApp.problemType === consultation.type,
        )
      ) {
        // There is a new initial quiz to be actioned.
        return false;
      }

      if (
        completedConsultationStatuses.includes(consultation.status) &&
        (!consultation?.treatment ||
          consultation.treatment.status === 'CANCELED')
      ) {
        return false;
      }

      if (consultationIdsWithPurchases?.includes(consultation.id)) {
        // We want to exclude consultations if there is a purchase that has a
        // context associated to a consultation because we'll render the
        // consultation information within the purchase component.
        return false;
      }

      if (
        !completedConsultationStatuses.includes(consultation.status) &&
        consultation.stage === 'REVIEW' &&
        visiblePurchases?.some(
          (p) => p.status === 'PAUSED' && p.problemType === consultation.type,
        )
      ) {
        // We don't want to show pending review consultations with the same
        // type of paused purchases because we'll render the purchase details.
        return false;
      }

      return true;
    });

    const quizCards = quizApplicationsWithoutConsultations.map(
      (quizApplication): ProfileQuizCard<Q> => ({
        treatmentState: 'quiz',
        quizApplication,
        get key(): string {
          return `${this.treatmentState}-${quizApplication.id}`;
        },
      }),
    );

    const consultationCards = visibleConsultations.map(
      (consultation): ProfileConsultationCard<C> => ({
        treatmentState: 'consultation',
        consultation,
        get key(): string {
          return `${this.treatmentState}-${consultation.id}`;
        },
      }),
    );

    const purchaseCards =
      visiblePurchases?.map(
        (purchase): ProfilePurchaseCard<P, C> => ({
          treatmentState: 'purchase',
          purchase,
          key: purchase.id,
          consultation: purchase.problemType
            ? problemTypeToReviewConsultationMap.get(purchase.problemType)
            : undefined,
        }),
      ) ?? [];

    return [...quizCards, ...consultationCards, ...purchaseCards];
  }, [data]);
}

useProfileCards.fragment = gql`
  fragment UseProfileCardsQuizApplication on QuizApplication {
    id
    problemType
    createdAt
    archivedAt
  }

  fragment UseProfileCardsConsultation on Consultation {
    id
    type
    stage
    createdAt
    isApproved
    purchasePrompt {
      id
    }
    typeIsFlexi
    quizApplication {
      id
      problemType
    }
    status
    treatment {
      id
      status
    }
    practitionerMessagedAt
  }

  fragment UseProfileCardsPurchase on Purchase {
    id
    status
    problemType
    isVisibleInProfilePage
    contexts {
      id
      consultations {
        id
      }
    }
  }
`;
