import { getAppEnv } from '@customer-frontend/types';
import { ensureEnv, Environment } from '@customer-frontend/environment';

const appEnv = getAppEnv(ensureEnv(`REACT_APP_APPLICATION_ENVIRONMENT`));

export const environment: Environment = {
  apiUrl: ensureEnv(`REACT_APP_API_URL`),
  landingPageUrl: ensureEnv(`REACT_APP_LANDING_PAGE_URL`),
  appEnv,
  stripe: ensureEnv(`REACT_APP_STRIPE_PUBLIC_KEY`),
  isProd: appEnv === 'production',
  datadogToken: ensureEnv('REACT_APP_DATADOG_TOKEN'),
  datadogRumApplicationId: ensureEnv('REACT_APP_DATADOG_RUM_APPLICATION_ID'),
  datadogRumToken: ensureEnv('REACT_APP_DATADOG_RUM_TOKEN'),
  segmentKey: ensureEnv(`REACT_APP_SEGMENT_PUBLIC_KEY`),
  launchDarklyClientID: ensureEnv('REACT_APP_LAUNCH_DARKLY_CLIENT_ID'),
  zipMerchantId: ensureEnv('REACT_APP_ZIP_MERCHANT_ID'),
  educationProgramId: ensureEnv('REACT_APP_EDUCATION_PROGRAM_ID'),
  streamAppKey: ensureEnv('REACT_APP_STREAM_APP_KEY'),
  googlePlacesApiKey: ensureEnv(`REACT_APP_GOOGLE_PLACES_API_KEY`),
};
