import { getConfig } from '@customer-frontend/config';
import { createLogger } from '@customer-frontend/logger';
import { environment } from 'utils/environment';

const config = getConfig();

export const logger = createLogger({
  appEnv: environment.appEnv,
  brand: config.brand,
  datadogToken: environment.datadogToken,
});
