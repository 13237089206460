import {
  ExperimentPaymentPlanVariant,
  OtcScheduleCadence,
} from '@customer-frontend/graphql-types';
import { calculatePaymentPlanChargeAmount } from './calculate-experiment-payment-plan-charge-amount';
import { CartLineItem } from '../line-items';

export const calculatePaymentPlanChargeAmountFromLineItems = ({
  lineItems,
  experimentPaymentPlan,
  otcSchedules,
}: {
  lineItems: CartLineItem[];
  experimentPaymentPlan: {
    paymentPlan: ExperimentPaymentPlanVariant;
    discountPercentage: number;
  };
  otcSchedules?: {
    isActive: boolean;
    cadence: OtcScheduleCadence;
    product: {
      variants: {
        price?: number;
      }[];
    };
    quantity?: number;
  }[];
}): { totalPrice: number; amountSaved: number; perOrder: number } => {
  let oneTimeCharge = 0;
  let recurringCharge = 0;

  for (const os of otcSchedules ?? []) {
    if (!os.isActive) {
      continue;
    }

    if (os.cadence === 'RECURRING') {
      recurringCharge += os.product.variants.at(0)?.price ?? 0;
    } else {
      oneTimeCharge += os.product.variants.at(0)?.price ?? 0;
    }
  }

  for (const li of lineItems) {
    if (li.isRecurring || li.productType === 'RX') {
      recurringCharge += li.price;
    } else {
      oneTimeCharge += li.price;
    }
  }

  return calculatePaymentPlanChargeAmount({
    recurringCharge,
    oneTimeCharge,
    experimentPaymentPlan,
  });
};
