import { SystemStyleObject } from '@chakra-ui/theme-tools';

interface States {
  default: string;
  selected: string;
}

interface ToggleTabsThemeProps {
  focusColor: string;
  hoverBgColor?: string;
  hoverFontColor?: string;
  fontColor: string;
  fontWeight?: number;
  selectedColor: string;
  selectedFontColor?: string;
  borderColor: States;
}

export const getToggleTabsVariants = (
  props: ToggleTabsThemeProps,
): SystemStyleObject => ({
  'toggle-tab': {
    flex: '1',
    h: 'initial',
    px: '1rem',
    py: '0.5rem',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    bg: 'transparent',
    color: props.fontColor,
    fontWeight: props.fontWeight ?? 700,
    border: `0.063rem solid ${props.borderColor.default}`, // 1px
    opacity: 0.8,
    _hover: {
      bg: props.hoverBgColor ?? props.selectedColor,
      color: props.hoverFontColor ?? props.selectedFontColor,
      opacity: 1,
    },
    _focus: {
      boxShadow: `0 0 0 0.188rem ${props.focusColor}`, // 3px
    },
  },
  'toggle-tab-selected': {
    flex: '1',
    h: 'initial',
    px: '1rem',
    py: '0.5rem',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    color: props.selectedFontColor ?? props.fontColor,
    fontWeight: props.fontWeight ?? 700,
    border: `0.063rem solid ${props.borderColor.selected}`, // 1px
    bg: props.selectedColor,
    _focus: {
      boxShadow: `0 0 0 0.188rem ${props.focusColor}`, // 3px
    },
  },
});
