import { ComponentStyleConfig } from '@chakra-ui/react';
import { getImageUploadStyles, imageUploadParts as parts } from '../../shared';

export const ImageUpload: ComponentStyleConfig = {
  parts,
  baseStyle: (props) =>
    getImageUploadStyles({
      props,
      styleOverrides: {
        container: {
          padding: '0px',
          borderRadius: '0px',
          bg: 'transparent',
          border: 'none',
        },
        placeholder: {
          border: 'none',
          backgroundColor: 'transparent',
        },
      },
    }),
};
