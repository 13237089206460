import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from './layout';
import { PageHeader } from './page-header';
import { Button, CardPalette } from '@eucalyptusvc/design-system';
import {
  MedicareCollectionForm,
  MedicareFormOnPage,
} from '@customer-frontend/healthcare-collection';
import { getConfig } from '@customer-frontend/config';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import { FormattedMessage, useIntl } from 'react-intl';

type ProfileMedicarePageProps = {
  className?: string;
  backToProfilePath: string;
  cardPalette?: CardPalette;
};

export const ProfileMedicarePage = ({
  className,
  backToProfilePath,
  cardPalette,
}: ProfileMedicarePageProps): ReactElement => {
  const config = getConfig();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const onBackToProfile = (): void => history.push(backToProfilePath);

  return (
    <Layout className={className}>
      <PageHeader
        title={formatMessage({
          defaultMessage: 'Confirm your Medicare details',
          description: 'Confirm the public health system identifying details',
        })}
        description={formatMessage({
          defaultMessage:
            'We require this to generate an eScript if you are prescribed a treatment. Enter your details as they appear on your Medicare card.',
          description:
            'Explains why local healthcare details are required, specifically for electronic script generation',
        })}
      />
      <MedicareCollectionForm
        validationLevel="all"
        submitButtonText={formatMessage({
          defaultMessage: 'Save',
        })}
        onPage={MedicareFormOnPage.patientProfile}
        cardPalette={cardPalette}
      />
      <div>
        <Button
          level="secondary"
          palette={getSecondaryButtonPalette(config.brand)}
          isFullWidth
          onClick={onBackToProfile}
        >
          <FormattedMessage defaultMessage="Back to profile" />
        </Button>
      </div>
    </Layout>
  );
};
