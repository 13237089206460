import React from 'react';
import { FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { ExperimentWeightLossGraphQuery } from '@customer-frontend/graphql-types';

import { useQuizState } from '@customer-frontend/quiz';
import {
  Button,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';

import { getPrimaryButtonPalette } from '../../helpers';
import { getConfig } from '@customer-frontend/config';

import { WeightLossGraphSVG } from './weight-loss-graph-svg';

type ExperimentWeightLossGraphProps = {
  isLoading: boolean;
};

export function ExperimentWeightLossGraph({
  isLoading,
}: ExperimentWeightLossGraphProps): React.ReactElement {
  const quizState = useQuizState();
  const config = getConfig();

  const { data, loading } = useQuery<ExperimentWeightLossGraphQuery>(
    gql`
      query ExperimentWeightLossGraph {
        profile {
          id
          firstName
          shortAddressableName
          preferredUnitSystem
        }
      }
    `,
  );

  const weightAnswer = quizState.quizApplication?.answers.find(
    (a) => a.question?.shortcode === 'weight',
  );
  const currentWeightAnswer = weightAnswer?.answer;
  const initialWeight = Math.round(Number(currentWeightAnswer));

  // TODO: https://linear.app/eucalyptus/issue/PG-15/support-toggling-between-kgs-and-stones

  if (loading || !initialWeight) {
    return (
      <>
        <div className="flex flex-col items-center text-center pb-6">
          <Typography size="lg">
            <FormattedMessage
              defaultMessage="Calculating your weight loss journey..."
              description="Calculating weight loss journey"
            />
          </Typography>
        </div>
        <div className="flex justify-center align-middle">
          <LoadingSpinner />
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col items-center text-center gap-6">
      <WeightLossGraphSVG
        firstName={
          data?.profile?.shortAddressableName ?? data?.profile?.firstName
        }
        initialWeight={initialWeight}
        preferredUnitSystem={data?.profile?.preferredUnitSystem}
        showDisclaimer
      />
      <Button
        level="primary"
        palette={getPrimaryButtonPalette(config.brand)}
        isLoading={isLoading}
        isSubmit
        isFullWidth
      >
        <FormattedMessage defaultMessage="Next" description="Next" />
      </Button>
    </div>
  );
}
