import { type SystemStyleObject } from '@chakra-ui/react';

interface NavTabsThemeProps {
  baseStyle?: {
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string | number;
    focusBorderColor?: string;
    hoverBgColor?: string;
  };
  borderAround: {
    borderRadius: string;
    borderColor: string;
    background?: string;
    color: string;
    opacity?: number;
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string | number;
    _activeLink: {
      background: string;
      color: string;
      opacity?: number;
    };
  };
  borderBottom: {
    borderColor: string;
    color: string;
    opacity?: number;
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string | number;
    _activeLink: {
      borderColor: string;
      color: string;
      opacity?: number;
    };
  };
  defaultProps?: {
    size?: 'md';
    variant?: NavTabVariants;
  };
}

export type NavTabVariants = 'border-around' | 'border-bottom';

export const getNavTabsComponent = ({
  baseStyle,
  borderAround,
  borderBottom,
  defaultProps,
}: NavTabsThemeProps): SystemStyleObject => ({
  NavTabs: {
    baseStyle: {
      navTabList: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        overflow: 'visible',
      },

      navTab: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontFamily: baseStyle?.fontFamily,
        fontSize: baseStyle?.fontSize,
        fontWeight: baseStyle?.fontWeight,
        textAlign: 'center',
        userSelect: 'none',

        _hover: {
          textDecoration: 'none',
          backgroundColor: baseStyle?.hoverBgColor,
        },
        _focus: {
          boxShadow: `0 0 0 0.188rem ${baseStyle?.focusBorderColor}`,
        },
        _activeLink: {
          boxShadow: `0 0 0 0.188rem ${baseStyle?.focusBorderColor}`,
        },
      },
    },

    sizes: {
      sm: {
        navTab: {
          padding: '0.5rem',
        },
      },
      md: {
        navTab: {
          padding: '1rem 0.5rem',
        },
      },
    },

    variants: {
      'border-around': {
        navTabList: {
          border: `1px solid ${borderAround.borderColor}`,
          borderRadius: borderAround.borderRadius,
        },

        navTab: {
          background: borderAround.background,
          color: borderAround.color,
          opacity: borderAround.opacity,
          fontFamily: borderAround.fontFamily,
          fontSize: borderAround.fontSize,
          fontWeight: borderAround.fontWeight,
          _notFirst: {
            borderLeft: `1px solid ${borderAround.borderColor}`,
          },
          _hover: {
            _first: {
              borderLeftRadius: borderAround.borderRadius,
            },
            _last: {
              borderRightRadius: borderAround.borderRadius,
            },
          },
          _focus: {
            _first: {
              borderLeftRadius: borderAround.borderRadius,
            },
            _last: {
              borderRightRadius: borderAround.borderRadius,
            },
          },

          _activeLink: {
            background: borderAround._activeLink.background,
            color: borderAround._activeLink.color,
            opacity: borderAround._activeLink.opacity,
            _first: {
              borderLeftRadius: borderAround.borderRadius,
            },
            _last: {
              borderRightRadius: borderAround.borderRadius,
            },
          },
        },
      },

      'border-bottom': {
        navTab: {
          borderBottom: `2px solid ${borderBottom.borderColor}`,
          color: borderBottom.color,
          opacity: borderBottom.opacity,
          fontFamily: borderBottom.fontFamily,
          fontSize: borderBottom.fontSize,
          fontWeight: borderBottom.fontWeight,
          _activeLink: {
            borderColor: borderBottom._activeLink.borderColor,
            color: borderBottom._activeLink.color,
            opacity: borderBottom._activeLink.opacity,
          },
        },
      },
    },

    defaultProps: {
      size: defaultProps?.size ?? 'md',
      variant: defaultProps?.variant ?? 'border-around',
    },
  },
});
