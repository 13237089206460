import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { ComponentStyleConfig, SystemStyleObject } from '@chakra-ui/react';
interface DividerThemeConfig {
  color: string;
  opacity?: number;
  alternateColor?: string;
}

export const getDividerStyles = (
  config: DividerThemeConfig,
): ComponentStyleConfig => ({
  baseStyle: (props: StyleFunctionProps): SystemStyleObject => ({
    borderStyle: 'solid',
    borderColor:
      props.palette === 'default'
        ? config.color
        : config.alternateColor || '#fff',
    opacity: config?.opacity ?? 0.6,
  }),
  variants: {
    divider: (props: StyleFunctionProps): SystemStyleObject => ({
      margin: `-${props.size}`,
      mb: '1rem',
      mt: '0.5rem',
    }),
    separator: {
      my: '1.25rem',
    },
  },
});
