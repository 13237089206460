import React from 'react';
import { ModalBody, ModalHeader, ModalCloseButton } from '@chakra-ui/react';
import { ModalProps, StringOrElement } from './types';
import { ModalWrapper } from './wrapper';
import { Typography } from '../typography';
import { useResponsive } from '../../hooks';
import { useThemeExtension } from '../../theme/shared';

const ModalTitle = ({
  title,
  titleColor,
  isBold,
}: {
  title?: StringOrElement;
  titleColor?: string;
  isBold?: boolean;
}): JSX.Element => {
  if (!title) {
    // Empty div needed when no title, to ensure correct close button positioning
    return <div />;
  }
  return typeof title === 'string' ? (
    <Typography size="md" isBold={isBold} color={titleColor}>
      {title}
    </Typography>
  ) : (
    title
  );
};

/** A **Modal** is used to immediately focus a user's attention to either
 * present or collect information. It is overlaid on top of page content until
 * dismissed.
 *
 * To require a user's response, and prevent them from dismissing the modal by
 * clicking the background overlay, you should use an **ActionModal** instead.
 **/
export const Modal = ({
  title,
  isOpen,
  onClose,
  children,
  size = 'md',
}: ModalProps): React.ReactElement => {
  const { isMobile } = useResponsive();

  const modalThemeColor = useThemeExtension<string>('modal.color', 'inherit');
  const isTitleBold =
    useThemeExtension<boolean>('modal.isTitleBold', false) === true;
  const responsiveVariant = `${size}${isMobile ? '-mobile' : ''}`;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} variant={responsiveVariant}>
      <ModalHeader>
        <ModalTitle
          title={title}
          titleColor={modalThemeColor}
          isBold={isTitleBold}
        />
        <ModalCloseButton color={modalThemeColor} />
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ModalWrapper>
  );
};
