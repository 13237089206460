import React, { useEffect, useRef, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { gql } from '@apollo/client';
import { useTheme } from '@chakra-ui/react';
import {
  useCreateOrRetrieveStripeSetupIntentMutation,
  useStripePublicKeyLazyQuery,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { BrandTheme, LoadingSpinner } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';
import { type Logger } from '@customer-frontend/logger';
import {
  getWithFallbacks,
  sharedColors,
} from '@eucalyptusvc/design-system/src/theme/shared';

/** Used for type generation */
export const createOrRetrieveStripeSetupIntentDocument = gql`
  mutation CreateOrRetrieveStripeSetupIntent {
    createOrRetrieveStripeSetupIntent {
      id
      clientSecret
    }
  }
`;
export const getStripePublicKeyDocument = gql`
  query StripePublicKey {
    stripeAccount {
      publicKey
    }
  }
`;

interface StripeBrandThemeType {
  borderRadius: string;
  colorPrimary: string;
  colorBackground: string;
  colorText: string;
  border: string;
  focusBorder: string;
  invalidBoxShadow: string;
  theme: 'flat' | 'stripe' | 'night';
}

export const StripeProvider = ({
  api,
  children,
  stripeSdkOnly = false,
  logger,
  stripeBrandTheme,
}: {
  api: 'paymentIntents' | 'charges';
  children: React.ReactNode;
  stripeSdkOnly?: boolean; // This is used for a scenario we only need stripe SDK but doesn't need to make a payment.
  logger: Logger;
  stripeBrandTheme?: StripeBrandThemeType;
}): React.ReactElement => {
  const { locale } = getConfig();
  const environment = useEnvironment();
  const theme = useTheme<BrandTheme>();
  const [createOrRetrieveStripeSetupIntent] =
    useCreateOrRetrieveStripeSetupIntentMutation();
  const [getStripePublicKeyQuery] = useStripePublicKeyLazyQuery({
    context: {
      skipErrorNotification: true,
    },
  });
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const isInitialised = useRef(false);

  useEffect(() => {
    if (api === 'paymentIntents' && !stripeSdkOnly && !isInitialised.current) {
      // StrictMode makes this run twice which creates two stripe customers and results in error the first time this is loaded
      // this is not trivial to handle in API as we already check for existing customer
      isInitialised.current = true;
      createOrRetrieveStripeSetupIntent().then((response) => {
        if (response.data?.createOrRetrieveStripeSetupIntent) {
          setClientSecret(
            response.data.createOrRetrieveStripeSetupIntent.clientSecret,
          );
        }
      });
    }
  }, [createOrRetrieveStripeSetupIntent, api, stripeSdkOnly]);

  useEffect(() => {
    if (clientSecret || api === 'charges' || stripeSdkOnly) {
      getStripePublicKeyQuery().then(({ data: stripeAccountData }) => {
        const publicKey =
          stripeAccountData?.stripeAccount?.publicKey || environment.stripe;
        const promise = loadStripe(publicKey).catch((error: unknown) => {
          if (error instanceof Error) {
            logger.error(
              `Loading Stripe within StripeProvider: ${error.message}`,
            );
          }
          if (typeof error === 'string') {
            logger.error(`Loading Stripe within StripeProvider: ${error}`);
          }

          logger.error('Loading Stripe within StripeProvider', { error });
          return null;
        });
        setStripePromise(promise);
      });
    }
  }, [
    clientSecret,
    api,
    getStripePublicKeyQuery,
    stripeSdkOnly,
    logger,
    environment.stripe,
  ]);

  if (
    (!clientSecret && api === 'paymentIntents' && !stripeSdkOnly) ||
    !stripePromise
  ) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: locale as StripeElementLocale,
        clientSecret: clientSecret ?? undefined,
        appearance: {
          theme: stripeBrandTheme?.theme || 'flat',
          variables: {
            borderRadius: stripeBrandTheme?.borderRadius || '4px',
            colorPrimary:
              stripeBrandTheme?.colorPrimary || theme.colors.primary[500],
            colorBackground:
              stripeBrandTheme?.colorBackground || theme.colors.primary[100],
            colorText: stripeBrandTheme?.colorText || theme.colors.primary[500],
            colorDanger: sharedColors.status.error[500],
            fontFamily: getWithFallbacks('Atlas Grotesk'),
          },
          rules: {
            '.Input': {
              border: stripeBrandTheme?.border || 'none',
              padding: '16px',
              boxShadow: 'none',
            },
            '.Input--invalid': {
              boxShadow:
                stripeBrandTheme?.invalidBoxShadow ||
                '0 0 0 1px var(--colorDanger)',
              borderColor: 'var(--colorDanger)',
            },
            '.Input:focus': {
              ...(stripeBrandTheme?.focusBorder && {
                border: stripeBrandTheme.focusBorder,
              }),
            },
            '.Error': {
              fontWeight: '500',
              fontSize: '12px',
              margin: '16px 0',
              lineHeight: '150%',
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
            },
            '.Label': {
              fontWeight: '500',
              fontSize: '14px',
            },
          },
        },
      }}
    >
      {children}
    </Elements>
  );
};
