import { Typography } from '@eucalyptusvc/design-system';
import {
  MembershipStatus,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ProcessingClock } from '../assets/status-icon-clock.svg';
import { ReactComponent as StatusPause } from '../assets/status-pause.svg';
import { ReactComponent as ProcessingPayment } from '../assets/status-processing-payment.svg';
import { ReactComponent as StatusProcessing } from '../assets/status-processing.svg';
import { ReactComponent as StatusTick } from '../assets/status-tick.svg';
import { ReactComponent as AwaitingVerifyId } from '../assets/status-awaiting-verify-id.svg';
import { ReactComponent as PaymentFailed } from '../assets/status-payment-failed.svg';

export const formatStatusText = (
  status: TreatmentStatus | MembershipStatus,
): JSX.Element | undefined => {
  switch (status) {
    case 'ACTIVE':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Active"
        />
      );
    case 'PROCESSING_ORDER':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Processing order"
        />
      );
    case 'FOLLOW_UP':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Follow up"
        />
      );
    case 'PAUSED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Paused"
        />
      );
    case 'PROCESSING_PAYMENT':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Processing payment"
        />
      );
    case 'REVIEW':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="In review"
        />
      );
    case 'AWAITING_VERIFY_ID':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Awaiting ID verification"
        />
      );
    case 'PAYMENT_FAILED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Payment failed"
        />
      );
    case 'CANCELED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Cancelled"
        />
      );
    case 'COMPLETED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Completed"
        />
      );
    case 'CREATED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Created"
        />
      );
    case 'EXPIRED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Expired"
        />
      );
    case 'PENDING':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Pending"
        />
      );
    case 'PENDING_RENEW':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Pending review"
        />
      );
    case 'RENEW_FAILED':
      return (
        <FormattedMessage
          description="Label for treatment or membership status"
          defaultMessage="Renew failed"
        />
      );
    default:
      return undefined;
  }
};

export const getTreatmentStatusIcon = (
  status: TreatmentStatus | MembershipStatus,
  className?: string,
): JSX.Element | null => {
  switch (status) {
    case 'ACTIVE':
      return <StatusTick className={className} style={{ fill: '#14763E' }} />;
    case 'PROCESSING_ORDER':
    case 'FOLLOW_UP':
      return <StatusProcessing className={className} />;
    case 'PAUSED':
      return <StatusPause />;
    case 'PROCESSING_PAYMENT':
      return <ProcessingPayment className={className} />;
    case 'REVIEW':
      return <ProcessingClock className={className} />;
    case 'AWAITING_VERIFY_ID':
      return <AwaitingVerifyId className={className} />;
    case 'PAYMENT_FAILED':
      return <PaymentFailed className={className} />;
    default:
      return null;
  }
};

export const IconTypeStatus = ({
  status,
  className,
}: {
  status: TreatmentStatus | MembershipStatus;
  className?: string;
}): JSX.Element => {
  const statusIcon = getTreatmentStatusIcon(status, className);
  return (
    <div
      className={`flex flex-row items-center justify-center capitalize ${className}`}
    >
      {statusIcon && <div className="mr-1">{statusIcon}</div>}
      <Typography size="paragraph" isBold>
        {formatStatusText(status)}
      </Typography>
    </div>
  );
};
