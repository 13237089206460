import { Config } from '../type';
import { defaultConfig } from './default';

const HELP_ARTICLE_URL = 'https://help.pilot.com.au/hc/en-us/articles/';

export const PilotConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'pilot',
  brand: 'pilot',
  loginUrl: '/login',
  title: 'Pilot',
  stripeApi: 'CHARGE',
  country: 'Australia',
  countryCode: 'AU',
  locale: 'en-AU',
  currency: 'AUD',
  emergencyNumber: '000',
  healthlineEnabled: true,
  supportEmail: 'gday@pilot.com.au',
  medicalEmail: 'medicalteam@pilot.com.au',
  rejectionContactLink: 'mailto:medicalteam@pilot.com.au',
  helpCentreUrl: 'https://help.pilot.com.au/hc/en-us',
  zendeskRequestUrl: 'https://help.pilot.com.au/hc/en-us/requests/new',
  problemTypeZendeskParams: {
    ACNE: { ticket_form_id: '15566336780569' },
    ERECTILE: { ticket_form_id: '15566282417305' },
    HAIR: { ticket_form_id: '15566307473049' },
    HERPES: { ticket_form_id: '15566371632537' },
    PREMATURE: { ticket_form_id: '15566333372313' },
    SKIN_GENERAL: { ticket_form_id: '15566336780569' },
    SLEEP: { ticket_form_id: '15566335580441' },
    WEIGHT_LOSS: { ticket_form_id: '15566280012825' },
  },
  weightCompoundingZendeskParams: {
    ticket_form_id: '15566280012825',
    tf_15542333724569: 'compounding_query',
  },
  weightPausingAccountZendeskParams: {
    ticket_form_id: '15566280012825',
    tf_15542333724569: 'change_or_edit_account',
    tf_15562527346841: 'account_pause_clone',
  },
  weightRefillNowZendeskParams: {
    ticket_form_id: '15566280012825',
    tf_15542333724569: 'medical_support',
    tf_15562018357657: 'med_err_clone',
  },
  weightResumeNowZendeskParams: {
    ticket_form_id: '15566280012825',
    tf_15542333724569: 'change_or_edit_account',
    tf_15562527346841: 'account_restart_clone',
  },
  weightFaqReadingCollectionId: '626a2cb9-e1aa-4a4a-a69a-8f25060b16d0',
  trustpilotLink: 'https://au.trustpilot.com/review/pilot.com.au',
  contactUsUrl: 'https://pilot.com.au/contact',
  consultationPriceCents: 2000,
  weightFacebookCommunityUrl:
    'https://www.facebook.com/groups/pilotmetabolicresetcommunity',
  otcSubscriptions: true,
  coldStorageAddressValidation: true,
  enableZip: true,
  showOnboardingQuiz: true,
  enableMedicare: true,
  scalesProvided: false,
  helpArticles: {
    ihiLinkingWithMedicare: `${HELP_ARTICLE_URL}21419310915737`,
    ihiLinkingNoMedicare: `${HELP_ARTICLE_URL}21517353288985`,
    healthInsurance: `${HELP_ARTICLE_URL}15625501036185`,
    cancelTreatment: `${HELP_ARTICLE_URL}4408473929369`,
    healthcareIdentifier: `${HELP_ARTICLE_URL}20775972186137`,
    pharmacyInvoices: `${HELP_ARTICLE_URL}15625440627865`,
  },
  localisedAssets: {
    zendeskMessageUsLogo: '/assets/text-pictogram.svg',
  },
  alternativeShippingAddressPost:
    'https://help.pilot.com.au/hc/en-us/articles/4408470129689',
  addressConfig: {
    numberBeforeStreet: true,
    showStateInput: true,
    validateShippingAddressWithinRange: true,
  },
  purchasePrompts: {
    initialPurchaseContents: 'expanded',
    initialPurchaseConfirmPersonalDetails: false,
    showCheckoutReassurance: true,
    initialPurchaseAddresses: new Set(['shipping'] as const),
    initialPurchaseShowSimplifiedShippingAddressForm: true,
    collectDeliveryInstructionsUpfront: false,
    collectDeliveryInstructions: true,
    supportShippingAddressValidation: true,
    showMarketingOptInCheckbox: false,
    showDeliveryConstraintCard: true,
    showTermsAndConditionsCheckbox: false,
    showZendeskSupportCard: true,
  },
  purchaseActivationFlow: {
    moneyBackGuaranteeDays: 30,
  },
  weightDashboardEnabled: true,
};
