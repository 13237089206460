import { getWithFallbacks } from '../../shared/fonts';
import {
  getLinkButtonVariants,
  getListButtonVariants,
  getToggleTabsVariants,
} from '../../shared/components';
import { colors } from '../palette';

export const button = {
  baseStyle: {
    fontFamily: getWithFallbacks('Atlas Grotesk'),
    fontWeight: 500,
    borderRadius: '0.5rem', // 8px
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 0.188rem ${colors.secondary.grey[400]}`, // 3px
    },
    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    sm: {
      h: '2.125rem', // 34px
      px: '1rem', // 16px
    },
    md: {
      h: '3rem', // 48px
      px: '1.5rem', // 24px
    },
    lg: {
      h: '3.5rem', // 56px
      px: '1.5rem', // 24px
    },
  },
  variants: {
    primary: {
      color: colors.neutral.white,
      bg: colors.secondary.brown[500],
      _hover: {
        bg: colors.neutral.black,
        _disabled: {
          bg: colors.secondary.brown[500],
        },
      },
      _focus: {
        bg: colors.secondary.brown[500],
      },
    },
    'primary-alternate': {
      color: colors.neutral.white,
      bg: colors.secondary.brown[500],
      _hover: {
        bg: colors.neutral.black,
        _disabled: {
          bg: colors.secondary.brown[500],
        },
      },
      _focus: {
        bg: colors.secondary.brown[500],
      },
    },
    'primary-white': {
      color: colors.secondary.brown[500],
      bg: 'white',
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.secondary.grey[400]}`, // 3px
      },
    },
    secondary: {
      color: colors.secondary.brown[500],
      bg: 'transparent',
      border: `0.063rem solid ${colors.secondary.brown[500]}`, // 1px
      _hover: {
        bg: colors.secondary.cream[400],
      },
      _focus: {
        bg: 'transparent',
        boxShadow: `0 0 0 0.1875rem ${colors.secondary.grey[400]}`, // 3px
      },
    },
    'secondary-alternate': {
      color: colors.secondary.brown[500],
      bg: 'transparent',
      border: `0.063rem solid ${colors.secondary.brown[500]}`, // 1px
      _hover: {
        bg: colors.secondary.cream[400],
      },
      _focus: {
        bg: 'transparent',
        boxShadow: `0 0 0 0.1875rem ${colors.secondary.grey[400]}`, // 3px
      },
    },
    'secondary-white': {
      color: 'white',
      bg: 'transparent',
      border: `0.063rem solid white`, // 1px
      _hover: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
      _focus: {
        bg: 'rgba(255, 255, 255, 0.3)',
        boxShadow: `0 0 0 0.188rem ${colors.secondary.grey[400]}`, // 3px
      },
      _disabled: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
    },
    ...getLinkButtonVariants({
      default: {
        color: colors.secondary.brown[500],
        hoverColor: colors.secondary.brown[300],
        focusColor: colors.secondary.brown[500],
        textDecoration: 'underline',
      },
      alternate: {
        color: colors.secondary.brown[500],
        hoverColor: colors.secondary.brown[300],
        focusColor: colors.secondary.brown[500],
      },
      white: {
        color: 'white',
        hoverColor: colors.secondary.brown[500],
        focusColor: colors.secondary.brown[500],
      },
    }),
    ...getListButtonVariants({
      textColor: colors.neutral.black,
      focusColor: colors.secondary.brown[500],
      focusBackground: colors.neutral.white,
      boxShadowWidth: '1px',
      border: {
        default: colors.neutral[300],
        hover: colors.secondary.brown[500],
        active: colors.secondary.brown[500],
        error: colors.status.error[500],
      },
      background: colors.neutral.white,
    }),
    ...getToggleTabsVariants({
      focusColor: colors.secondary.grey[400],
      hoverBgColor: colors.secondary.cream[400],
      hoverFontColor: colors.secondary.brown[500],
      fontColor: colors.secondary.brown[500],
      selectedColor: colors.secondary.brown[500],
      selectedFontColor: colors.neutral.white,
      borderColor: {
        default: colors.neutral[500],
        selected: colors.secondary.brown[500],
      },
    }),
  },
};
