import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import { ProblemType } from '@customer-frontend/graphql-types';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import { getZendeskRequestUrl } from '@customer-frontend/utils';
import {
  AccordionList,
  AccordionPalette,
  Button,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

interface AsyncConsultConfirmedProps {
  goBackToProfile: () => void;
  openChat: () => void;
  palette?: AccordionPalette;
  problemType?: ProblemType;
  requiresPrescriberCall?: boolean;
}

export const AsyncConsultConfirmed: React.FC<AsyncConsultConfirmedProps> = ({
  goBackToProfile,
  openChat,
  palette,
  problemType,
  requiresPrescriberCall,
}) => {
  const config = getConfig();
  const environment = useEnvironment();
  const consultConfig = useConsultationFlowConfig(problemType);

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const isSyncConsult = !!(
    (asyncConsultsEnabled || requiresPrescriberCall) &&
    consultConfig?.requiresPrescriberBooking
  );

  const helpLink = getZendeskRequestUrl({
    problemType,
  });

  return (
    <div className="h-full space-y-6 text-center">
      <Typography size="xl" isBold>
        <FormattedMessage defaultMessage="Consult confirmed" />
      </Typography>
      <div>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="We’ll connect you with a {isGb, select, true {prescriber} other {practitioner}} soon. You’ll receive an SMS when they start your text-based consult{isGb, select, true {.} other { (usually within 48 hours).}}"
            values={{ isGb: getConfig().countryCode === 'GB' }}
          />
        </Typography>
      </div>
      <div className="space-y-3">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="In the meantime, if you have any questions about your treatment or consult, please contact our <a>customer service team</a>."
            values={{
              a: (chunks) => (
                <a
                  href={helpLink}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      </div>
      <div className="space-y-8">
        <div className="flex flex-col gap-4 justify-center md:flex-row">
          {isSyncConsult && (
            <Button
              level="primary"
              palette={getPrimaryButtonPalette(config.brand)}
              onClick={openChat}
            >
              <FormattedMessage defaultMessage="Open consult chat" />
            </Button>
          )}
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={goBackToProfile}
          >
            <FormattedMessage defaultMessage="Return to profile" />
          </Button>
        </div>
        <div className="text-left">
          <AccordionList
            palette={palette}
            items={[
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="What to expect" />
                  </Typography>
                ),
                content: (
                  <div className="space-y-4">
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="We’ll send you an SMS when your {isGb, select, true {prescriber} other {practitioner}} starts your consult. All you’ll need to do is open the link provided in the text to respond."
                        values={{ isGb: getConfig().countryCode === 'GB' }}
                      />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage
                        defaultMessage="Following your consult, if your {isGb, select, true {prescriber} other {practitioner}} has determined that treatment is appropriate for you, you will be asked to confirm your order."
                        values={{ isGb: getConfig().countryCode === 'GB' }}
                      />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Once confirmed, your order will be prepared and sent to your nominated shipping address." />
                    </Typography>
                  </div>
                ),
              },
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="Frequently asked questions" />
                  </Typography>
                ),
                content: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage={`The team has written up answers to all the most common questions our patients ask. To read these, visit our <a>Frequently Asked Questions page.</a>`}
                      values={{
                        a: (chunks) => (
                          <a
                            href={`${environment.landingPageUrl}${config.urlPaths.faq}`}
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                      description="Async consult confirmed page"
                    />
                  </Typography>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
