import { Fragment, useMemo, createRef } from 'react';
import { Controller } from 'react-hook-form';
import add from 'date-fns/add';
import isWithinInterval from 'date-fns/isWithinInterval';
import {
  Button,
  Card,
  TextArea,
  Typography,
} from '@eucalyptusvc/design-system';
import {
  TitleType,
  formatDoctorName,
  useAnswerDoctorQuestionsForm,
} from '@customer-frontend/doctor';
import { requiredValidation } from '@customer-frontend/utils';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

import { MessageBubble } from '../message-bubble';
import { QuestionAnswer } from '../question-answer';
import { QuestionAnswerInput } from '../question-answer-input';

import { MessageTypes, ReviewThreadProps, ThreadMessage } from './types';
import { isSupportedAnswerType, buildReminderMessageInfo } from './utils';
import { useAddPatientQuestion } from './useAddPatientQuestion';
import { PendingQuestionAlert } from '../pending-question-alert';

import { ReactComponent as BellIcon } from '../assets/bell.svg';
import { ReactComponent as BellOffIcon } from '../assets/bell-off.svg';
import { ReactComponent as BellRingIcon } from '../assets/bell-ring.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { getConfig } from '@customer-frontend/config';

export const ReviewThread = ({
  consultationId,
  consultationStatus,
  problemType,
  doctor,
  canMessage,
  doctorQuestions,
  patientQuestions,
  reminders,
  imageInstructions,
  requiredAlertIcon,
  chatTheme,
  onSubmit,
  createObservationInput,
  getPlaceholderImgUrl,
}: ReviewThreadProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const shouldShowPractitionerBacklogMessaging = useFeatureFlagBoolean(
    'CFG_PRACTITIONER_BACKLOG_MESSAGING',
  );

  const {
    loading: drSubmitLoading,
    errors: doctorErrors,
    control: doctorControl,
    submit: doctorSubmit,
  } = useAnswerDoctorQuestionsForm(consultationId, async () => {
    await onSubmit?.();
  });

  const {
    loading: patientSubmitLoading,
    errors: patientErrors,
    control: patientControl,
    submit: patientSubmit,
  } = useAddPatientQuestion(consultationId, async () => {
    await onSubmit?.();
  });

  const config = getConfig();

  const pendingAnswers = doctorQuestions?.some(
    (question) => question.allowedAnswerType === 'TEXT' && !question.answer,
  );

  // create a mapping of the doctor questions to components and date
  const doctorMessages: ThreadMessage[] = useMemo(
    () =>
      doctorQuestions?.map((item) => {
        const ref = createRef<HTMLDivElement>();
        return {
          id: item.id,
          messageType: MessageTypes.DOCTOR_MESSAGE,
          allowedAnswerType: item.allowedAnswerType,
          date: new Date(item.createdAt),
          ref,
          answered: item.answer !== undefined && item.answer !== null,
          component: (isFirst: boolean, isLast: boolean) => (
            <Fragment key={item.id}>
              <MessageBubble
                ref={ref}
                className="w-4/5 xl:w-3/4"
                chatTheme={chatTheme}
                type="received"
                senderName={`${doctor?.firstName} ${doctor?.lastName}`}
                date={item.createdAt ? new Date(item.createdAt) : undefined}
                isFirst={isFirst}
                isLast={isLast}
                attachments={item.attachments}
                consultationId={consultationId}
                consultationQuestionId={item.id}
              >
                {item.value}
              </MessageBubble>
              {isSupportedAnswerType(item.allowedAnswerType) &&
                (item.answer ? (
                  <QuestionAnswer
                    type={item.allowedAnswerType}
                    answer={item.answer}
                    chatTheme={chatTheme}
                    consultationId={consultationId}
                    consultationQuestionId={item.id}
                  />
                ) : (
                  <QuestionAnswerInput
                    id={item.id}
                    name={`answers[${item.id}]`}
                    control={doctorControl}
                    type={item.allowedAnswerType}
                    problemType={problemType}
                    question={item.value}
                    errorMessage={doctorErrors?.answers?.[item.id]?.message}
                    chatTheme={chatTheme}
                    imageInstructions={imageInstructions}
                    consultationId={consultationId}
                    doctor={doctor}
                    submitPending={drSubmitLoading}
                    submit={doctorSubmit}
                    createObservationInput={createObservationInput}
                    getPlaceholderImgUrl={getPlaceholderImgUrl}
                  />
                ))}
            </Fragment>
          ),
        };
      }) ?? [],
    [
      chatTheme,
      consultationId,
      doctorControl,
      createObservationInput,
      doctor,
      doctorQuestions,
      doctorSubmit,
      drSubmitLoading,
      doctorErrors?.answers,
      getPlaceholderImgUrl,
      imageInstructions,
      problemType,
    ],
  );

  // create a mapping of the patient questions to components and date
  const patientMessages: ThreadMessage[] = useMemo(
    () =>
      patientQuestions?.map((item) => {
        const ref = createRef<HTMLDivElement>();
        return {
          id: item.id,
          ref,
          messageType: MessageTypes.PATIENT_MESSAGE,
          date: new Date(item.createdAt),
          component: (isFirst: boolean, isLast: boolean) => (
            <Fragment key={item.id}>
              <MessageBubble
                ref={ref}
                className="self-end w-4/5 xl:w-3/4"
                chatTheme={chatTheme}
                type="sent"
                senderName={formatMessage({ defaultMessage: 'You' })}
                date={item.createdAt ? new Date(item.createdAt) : undefined}
                isFirst={isFirst}
                isLast={isLast}
                consultationId={consultationId}
              >
                {item.value}
              </MessageBubble>
            </Fragment>
          ),
        };
      }) ?? [],
    [patientQuestions, chatTheme, consultationId, formatMessage],
  );

  // create a mapping of the reminders to components and date
  const consultationReminderInfo = buildReminderMessageInfo(reminders);
  const consultationReminders = consultationReminderInfo.map((reminder) => {
    const ref = createRef<HTMLDivElement>();

    let Icon: typeof BellOffIcon;
    switch (reminder.status) {
      case 'CANCELLED':
        Icon = BellOffIcon;
        break;
      case 'CREATED':
        Icon = BellIcon;
        break;
      case 'SENT':
        Icon = BellRingIcon;
        break;
    }

    return {
      id: reminder.id,
      ref,
      messageType: MessageTypes.REMINDER,
      date: new Date(reminder.createdAt ?? ''),
      component: (isFirst: boolean, isLast: boolean) => (
        <Fragment key={reminder.id}>
          <MessageBubble
            ref={ref}
            className="w-full"
            chatTheme={chatTheme}
            type="reminder"
            date={new Date(reminder.createdAt ?? '')}
            isFirst={isFirst}
            isLast={isLast}
            consultationId={consultationId}
          >
            <div className="flex items-center justify-center gap-x-2">
              <Icon className="w-4 h-4" />
              <Typography size="paragraph" isBold>
                {reminder.message}
              </Typography>
            </div>
          </MessageBubble>
        </Fragment>
      ),
    };
  });

  // merge the doctor and patient questions and consultation reminders and sort by their date
  const messages = [
    ...patientMessages,
    ...doctorMessages,
    ...consultationReminders,
  ].sort((a, b) => a.date.getTime() - b.date.getTime());

  const messageGroups = messages.reduce<ThreadMessage[][]>(
    (messageGroups, currMessage) => {
      const currentMessageGroup = messageGroups.pop();
      if (!currentMessageGroup) {
        return [[currMessage]];
      }

      // should group messages if all messages are from same person and last message was sent < 5 minutes ago
      const shouldGroup =
        currentMessageGroup.every(
          (message) => message.messageType === currMessage.messageType,
        ) &&
        isWithinInterval(currMessage.date, {
          start: currentMessageGroup[currentMessageGroup.length - 1].date,
          end: add(currentMessageGroup[currentMessageGroup.length - 1].date, {
            minutes: 5,
          }),
        });

      if (shouldGroup) {
        if (currentMessageGroup) {
          return [...messageGroups, [...currentMessageGroup, currMessage]];
        }
      }
      return [...messageGroups, currentMessageGroup, [currMessage]];
    },
    [],
  );

  const messageComponents = messageGroups.reduce<JSX.Element[]>(
    (messageComponents, currentGroup) => {
      const currentMessageComponents = currentGroup.map((message, index) =>
        message.component(index === 0, index === currentGroup.length - 1),
      );
      return [...messageComponents, ...currentMessageComponents];
    },
    [],
  );

  const lastMessage = messages[messages.length - 1];

  const foundQuestion = doctorMessages
    ?.sort((a, b) => b.date.getTime() - a.date.getTime())
    .find(
      (message) =>
        (message.allowedAnswerType === 'IMAGE' ||
          message.allowedAnswerType === 'OBSERVATION') &&
        !message.answered,
    );
  const requiredQuestion =
    foundQuestion?.id !== lastMessage?.id ? foundQuestion : undefined;
  const practitionerName = doctor
    ? formatDoctorName(doctor, TitleType.DOCTOR)
    : 'Your practitioner';

  return (
    <>
      <form onSubmit={doctorSubmit} noValidate>
        <div className="flex flex-col mb-2">{messageComponents}</div>
        {pendingAnswers && (
          <div className="mt-2">
            <Button
              level="primary"
              palette="alternate"
              isLoading={drSubmitLoading}
              isSubmit
              isFullWidth
            >
              Submit
            </Button>
          </div>
        )}
      </form>
      {requiredQuestion && (
        <PendingQuestionAlert
          chatTheme={chatTheme}
          questionType={requiredQuestion.allowedAnswerType}
          requiredAlertIcon={requiredAlertIcon}
          scrollToQuestion={() => {
            requiredQuestion?.ref.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            requiredQuestion?.ref.current?.focus();
          }}
        />
      )}
      {!canMessage &&
        !pendingAnswers &&
        consultationStatus === 'DOCTOR_ASSIGNED' && (
          <div className="px-4 pb-4">
            <Card palette="alternate" size="sm">
              <Typography size="paragraph" isBold>
                <FormattedMessage
                  defaultMessage="{practitionerName} is reviewing your responses and will get back to you as soon as possible. {shouldShowPractitionerBacklogMessaging, select, true {Please note we are currently experiencing a high volume of patient queries, so please allow some time for them to reply. You will be notified as soon as they have responded} other {You'll be able to respond once they reply}}. In the meantime, if you have any non-medical questions, reach out to our friendly support team <a>here</a>."
                  description="Info message specifying that a patient is unable to send a message until a practitioner has reviewed their prior messages and replied. Points patients to patient experience team for non-medical questions."
                  values={{
                    practitionerName,
                    a: (chunks) => (
                      <a className="underline" href={config.zendeskRequestUrl}>
                        {chunks}
                      </a>
                    ),
                    shouldShowPractitionerBacklogMessaging,
                  }}
                />
              </Typography>
            </Card>
          </div>
        )}
      {canMessage && !pendingAnswers && (
        <form onSubmit={patientSubmit} noValidate>
          <Controller
            name="question"
            control={patientControl}
            defaultValue=""
            rules={{ ...requiredValidation('message') }}
            render={(props) => {
              return (
                <>
                  <div className="mb-2">
                    <TextArea
                      {...props}
                      label="Your message"
                      placeholder="Write your message here"
                      palette={chatTheme.textInputPalette}
                      rows={4}
                      errorMessage={patientErrors?.question?.message}
                    />
                  </div>
                  <div className="mt-2">
                    <Button
                      level="primary"
                      palette={chatTheme.sendButtonPalette}
                      isLoading={patientSubmitLoading}
                      isDisabled={!props.value.trim()}
                      isSubmit
                      isFullWidth
                    >
                      Send
                    </Button>
                  </div>
                </>
              );
            }}
          />
        </form>
      )}
    </>
  );
};
