import React from 'react';
import { useInterval } from 'react-use';
import { useScript } from './useScript';

declare global {
  interface Window {
    __productReviewSettings?: { brandId: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __productReviewCallbackQueue?: any[];
  }
}

const SOURCE = 'https://cdn.productreview.com.au/assets/widgets/loader.js';

export type ProductReviewsWidgetType =
  | 'rich-rating-badge'
  | 'reviews-horizontal';

export type ProductReviewsScriptConfig = {
  widgetType: ProductReviewsWidgetType;
  brandId: string;
  identifier: string;
  configurationId: string;
};

export type UseAddProductReviewArgs = {
  config: ProductReviewsScriptConfig;
  onError?: (err: unknown) => void;
};

const INTERVAL_DELAY = 5;

// https://support.productreview.com.au/hc/en-us/articles/360001382895-How-do-I-use-my-Product-IDs-with-ProductReview-review-widgets-and-rating-badges-
export const useAddProductReviewsScript = ({
  config,
  onError,
}: UseAddProductReviewArgs): void => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { widgetType, brandId, identifier, configurationId } = config;

  useScript(SOURCE);

  useInterval(
    () => {
      if (window.__productReviewSettings) {
        try {
          window.__productReviewSettings = {
            brandId,
          };

          window.__productReviewCallbackQueue =
            window.__productReviewCallbackQueue || [];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.__productReviewCallbackQueue.push((ProductReview) => {
            ProductReview.use(widgetType, {
              identificationDetails: {
                type: 'single',
                strategy: 'from-internal-entry-id',
                identifier,
              },
              container: `#pr-${widgetType}-widget`,
              configurationId,
            });
          });
        } catch (err) {
          onError?.(err);
        } finally {
          setIsLoading(false);
        }
      }
    },
    isLoading ? INTERVAL_DELAY : null,
  );
};
