import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Divider,
  LoadingSpinner,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  useCancelSubscriptionV2,
  useSubscriptionV2,
} from '@customer-frontend/services';
import {
  NextShipmentCard,
  SubscriptionPlanProduct,
  SubscriptionPlanCard,
  SubscriptionActionModal,
} from '@customer-frontend/subscriptions';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';

export type SubscriptionV2ManageProps = {
  subscriptionId: string;
  onNoSubscriptionRedirectPath: string;
};

export const SubscriptionV2Manage: React.FC<SubscriptionV2ManageProps> = ({
  subscriptionId,
  onNoSubscriptionRedirectPath,
}) => {
  const notify = useNotification();
  const config = getConfig();
  const [showCancellationModal, setShowCancellationModal] =
    React.useState(false);
  const {
    data: subscription,
    loading,
    refetch: refetchSubscription,
  } = useSubscriptionV2({
    variables: {
      id: subscriptionId,
    },
  });

  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [cancelSubscription] = useCancelSubscriptionV2();

  const handleCancelSubscription = async (
    subscriptionId: string,
  ): Promise<void> => {
    try {
      setCancelLoading(true);

      await cancelSubscription({
        variables: {
          subscriptionId,
        },
      });

      notify.success({
        message: 'Subscription cancelled',
      });
      setShowCancellationModal(false);
      await refetchSubscription();
    } catch {
      // errors handled by graphql client
    } finally {
      setCancelLoading(false);
    }
  };

  const subscriptionProducts = useMemo<SubscriptionPlanProduct[]>(() => {
    if (!subscription?.subscriptionV2.subscriptionPlan) {
      return [];
    }

    return [
      {
        name: subscription.subscriptionV2.subscriptionPlan.name,
        photoUrl: subscription.subscriptionV2.subscriptionPlan.photo.url,
        price: subscription.subscriptionV2.subscriptionPlan.price,
      },
    ];
  }, [subscription?.subscriptionV2.subscriptionPlan]);

  const subtotal = subscriptionProducts.reduce(
    (sum, product) => product.price + sum,
    0,
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center p-3">
        <LoadingSpinner />
      </div>
    );
  }

  // Based on the API logic this should never happen as the permission check
  // should fail, but that behaviour could change, so better to guard against it.
  if (!subscription) {
    return <Redirect to={onNoSubscriptionRedirectPath} />;
  }

  const { subscriptionPlan, nextPaymentAt, status } =
    subscription.subscriptionV2;
  return (
    <div className="space-y-6 mb-16">
      <Typography size="xl" isBold element="h1">
        Your plan
      </Typography>
      {config.brand === 'pilot' && (
        <Divider variant="separator" palette="alternate" />
      )}
      <SubscriptionPlanCard
        {...subscriptionPlan}
        photoUrl={subscriptionPlan.photo.url}
        price={subscriptionPlan.price}
        nextPaymentDate={nextPaymentAt}
        status={status}
        subscriptionId={subscriptionId}
      />

      {nextPaymentAt && (
        <NextShipmentCard
          products={subscriptionProducts}
          nextPaymentDate={nextPaymentAt}
          subTotalCents={subtotal}
          grandTotalCents={subtotal}
        />
      )}

      {['ACTIVE', 'PROCESSING'].includes(status) && (
        <>
          <Typography size="medium-paragraph">
            Want to make changes to your plan? You may&nbsp;
            <button
              className="underline inline-block"
              onClick={() => setShowCancellationModal(true)}
            >
              cancel here
            </button>
            .
          </Typography>
          <SubscriptionActionModal
            onClose={() => setShowCancellationModal(false)}
            isOpen={showCancellationModal}
            onConfirm={() => handleCancelSubscription(subscriptionId)}
            confirmText="Cancel"
            bodyText={`Your next shipment scheduled on ${formatDate(
              mapBrandToAdaptersBrand(config.brand),
              nextPaymentAt ?? '',
              { dateStyle: 'long' },
            )} will be cancelled if you proceed. Any orders currently being processed cannot be cancelled here. Please email ${
              config.supportEmail
            } to cancel orders in flight.`}
            headingText="Cancel subscription"
            products={subscriptionProducts}
            planInterval={subscriptionPlan.interval}
            planIntervalCount={subscriptionPlan.intervalCount}
            loading={cancelLoading}
          />
        </>
      )}
    </div>
  );
};
