import {
  ProfileCard,
  ProfileConsultationCard,
  ProfilePurchaseCard,
  ProfileQuizCard,
} from './types';

export function filterQuizCards<Q, C, P>(
  cards: ProfileCard<Q, C, P>[],
): ProfileQuizCard<Q>[] {
  return cards.filter(
    (card): card is ProfileQuizCard<Q> => card.treatmentState === 'quiz',
  );
}

export function filterConsultationCards<Q, C, P>(
  cards: ProfileCard<Q, C, P>[],
): ProfileConsultationCard<C>[] {
  return cards.filter(
    (card): card is ProfileConsultationCard<C> =>
      card.treatmentState === 'consultation',
  );
}

export function filterPurchaseCards<Q, C, P>(
  cards: ProfileCard<Q, C, P>[],
): ProfilePurchaseCard<P, C>[] {
  return cards.filter(
    (card): card is ProfilePurchaseCard<P, C> =>
      card.treatmentState === 'purchase',
  );
}
