import React from 'react';
import {
  Button,
  Card,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { useParams } from 'react-router-dom';
import { Layout } from './layout';
import { gql, QueryResult, useQuery } from '@apollo/client';
import {
  Maybe,
  QuizApplicationResponse,
  User,
} from '@customer-frontend/graphql-types';
import {
  convertAndFormatWeight,
  formatISODateToLocale,
  formatName,
  getResponseFromShortCode,
} from '@customer-frontend/utils';
import {
  useConsultation,
  useHistoryGoBackBehaviour,
} from '@customer-frontend/services';
import {
  useConfirmFreeWeightConsultation,
  useGetDefaultClinicianNounByCountryCode,
} from '../logic/cp-redesign-utils';
import { getConfig } from '@customer-frontend/config';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import { FormattedMessage, useIntl } from 'react-intl';

interface ConfirmAnswersPageProps {
  className?: string;
  onReviewAnswers: (quizApplicationId: string) => void;
  onConfirmAnswers: (
    isProfileComplete: boolean,
    isInitialConsultation: boolean,
  ) => void;
  productAnswers?: { label: string; value: Maybe<string | number> }[];
  answerColor?: string;
  loading?: boolean;
}

export function ConfirmAnswersPage({
  className = '',
  onReviewAnswers,
  onConfirmAnswers,
  productAnswers,
  answerColor,
  loading = false,
}: ConfirmAnswersPageProps): React.ReactElement {
  useHistoryGoBackBehaviour();
  const config = getConfig();
  const clinicianByCountryCode = useGetDefaultClinicianNounByCountryCode(
    config.countryCode,
  );

  const { consultationId } = useParams<{
    consultationId: string;
  }>();

  const { data: consultation, loading: consultationLoading } =
    useConsultation(consultationId);
  const quizApplicationId = consultation?.consultation?.quizApplication?.id;

  const { data: medicalProfile, loading: medicalProfileLoading } =
    useMedicalProfile();

  const { loading: consultationPayLoading } = useConfirmFreeWeightConsultation({
    consultationId,
  });

  const handleConfirmAnswers = async (): Promise<void> => {
    if (!consultation?.consultation) {
      throw new Error('Consultation data is not available');
    }
    const isProfileComplete = !!(
      medicalProfile?.profile?.isPasswordSet && medicalProfile.profile.phone
    );
    onConfirmAnswers(
      isProfileComplete,
      consultation.consultation.stage === 'INITIAL',
    );
  };

  const isLoadingPatientAnswers =
    consultationLoading || medicalProfileLoading || loading;

  const quizAnswers = consultation?.consultation.quizApplication?.answers;

  return (
    <Layout className={className || 'py-10 md:py-12'}>
      <Typography size="lg" isBold textAlign="center">
        <FormattedMessage defaultMessage="Have we got this right?" />
      </Typography>
      <div className="mt-4">
        <Typography size="medium-paragraph" textAlign={'center'}>
          <FormattedMessage
            defaultMessage={`To create your medical record, your {clinicianByCountryCode} will need to confirm your details are correct. <strong>Please ensure everything below is truthful.</strong>`}
            values={{
              clinicianByCountryCode,
              strong: (chunk) => <strong>{chunk}</strong>,
            }}
          />
        </Typography>
      </div>
      {isLoadingPatientAnswers ? (
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <Card>
            <ul className="space-y-2 text-primary-500">
              <PatientMedicalRecordConfirmAttributes
                quizAnswers={quizAnswers}
                medicalProfile={medicalProfile}
                productAnswers={productAnswers}
                answerColor={answerColor}
              />
            </ul>
          </Card>
          <div className="space-y-4">
            <Button
              isFullWidth
              palette={getPrimaryButtonPalette(config.brand)}
              onClick={handleConfirmAnswers}
              isLoading={consultationPayLoading}
              isDisabled={consultationPayLoading}
            >
              <FormattedMessage defaultMessage="Yes, that looks right" />
            </Button>
            {quizApplicationId && (
              <Button
                level="secondary"
                palette={getSecondaryButtonPalette(config.brand)}
                onClick={() => onReviewAnswers(quizApplicationId)}
                isFullWidth
              >
                <FormattedMessage
                  description="Label for button to review questionnaire answers"
                  defaultMessage="I need to update my answers"
                />
              </Button>
            )}
          </div>
        </>
      )}
    </Layout>
  );
}

const PatientMedicalRecordConfirmAttributes = ({
  medicalProfile,
  productAnswers,
  answerColor,
  quizAnswers,
}: {
  medicalProfile: { profile: User } | undefined;
  productAnswers?: { label: string; value: Maybe<string | number> }[];
  answerColor?: string;
  quizAnswers?: QuizApplicationResponse[];
}): React.ReactElement => {
  const preferredUnitSystem = medicalProfile?.profile?.preferredUnitSystem;
  const weight = getResponseFromShortCode('weight', quizAnswers)?.answer;

  const formattedWeight =
    typeof weight === 'string'
      ? convertAndFormatWeight(weight, preferredUnitSystem)
      : weight;

  const dob = getResponseFromShortCode('dob', quizAnswers)?.answer;
  const { formatMessage } = useIntl();

  const resolvedProductAnswers = productAnswers || [
    {
      label: formatMessage({
        defaultMessage: 'Weight',
      }),
      value: formattedWeight,
    },
  ];
  const patientAnswers = [
    {
      label: formatMessage({
        defaultMessage: 'Full name',
      }),
      value: formatName(medicalProfile?.profile),
    },
    {
      label: formatMessage({
        defaultMessage: 'DOB',
        description: 'Date of birth',
      }),
      value: (dob && formatISODateToLocale(dob)) ?? '',
    },
    ...resolvedProductAnswers,
    {
      label: formatMessage({
        defaultMessage: 'Medications',
      }),
      value:
        getResponseFromShortCode('currentMedications', quizAnswers)?.answer ||
        'N/A',
    },
    {
      label: formatMessage({
        defaultMessage: 'Medical conditions',
      }),
      value:
        getResponseFromShortCode('conditions', quizAnswers)?.answer || 'N/A',
    },
    {
      label: formatMessage({
        defaultMessage: 'Allergies',
      }),
      value:
        getResponseFromShortCode('allergies', quizAnswers)?.answer || 'N/A',
    },
  ].filter(({ value }) => !!value);

  return (
    <ul className="space-y-2">
      {medicalProfile &&
        patientAnswers.map(({ label, value }) => (
          <li key={label}>
            <Typography size="xs" isBold element="span" color={answerColor}>
              {/* eslint-disable formatjs/no-literal-string-in-jsx, react/jsx-no-literals */}
              {label}:
              {/* eslint-disable-next-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
            </Typography>{' '}
            {/* eslint-enable formatjs/no-literal-string-in-jsx, react/jsx-no-literals */}
            <Typography size="sm" element="span" color={answerColor}>
              {value}
            </Typography>
          </li>
        ))}
    </ul>
  );
};

export const GET_PROFILE_HEALTH_QUERY = gql`
  query UseMedicalProfile {
    profile {
      id
      firstName
      lastName
      birthday
      phone
      preferredUnitSystem
      medicalProfile {
        id
        dob
        weight
        currentMedications
        allergies
        existingConditions
      }
      isPasswordSet
    }
  }
`;

const useMedicalProfile = (): QueryResult<{ profile: User }> => {
  return useQuery<{ profile: User }>(GET_PROFILE_HEALTH_QUERY, {
    fetchPolicy: 'network-only',
  });
};
