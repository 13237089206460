import React from 'react';
import {
  AuthProvider as SharedAuthProvider,
  UserInfo,
} from '@customer-frontend/auth';
import { Logger } from '@customer-frontend/logger';
// import { identify } from 'services/analytics';
import { eventService } from 'services/event';
import { authService } from '.';

function onAuthChanged(user?: UserInfo): void {
  if (user) {
    eventService.identify(user);
  }
}

export const AuthProvider = ({
  children,
  logger,
}: {
  children: React.ReactNode;
  logger: Logger;
}): React.ReactElement => {
  return (
    <SharedAuthProvider
      logger={logger}
      authService={authService}
      onAuthChanged={onAuthChanged}
    >
      {children}
    </SharedAuthProvider>
  );
};
