import { getConfig } from '@customer-frontend/config';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import {
  Button,
  ButtonPalette,
  LinkButton,
  Modal,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { useState } from 'react';
import clsx from 'clsx';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { gql, useMutation } from '@apollo/client';
import {
  ConfirmAsyncConsultationMutation,
  ConfirmAsyncConsultationMutationVariables,
} from '@customer-frontend/graphql-types';
import { useParams } from 'react-router-dom';

const confirmAsyncConsultationMutation = gql`
  mutation ConfirmAsyncConsultation($input: ConfirmAsyncConsultationInput!) {
    confirmAsyncConsultation(input: $input) {
      consultation {
        id
      }
    }
  }
`;

interface FURConsultChoiceProps {
  linkButtonPalette: ButtonPalette;
  onPhoneSelected: () => void;
  onTextSelected: () => void;
}

export const FURConsultChoice: React.FC<FURConsultChoiceProps> = ({
  linkButtonPalette,
  onPhoneSelected,
  onTextSelected,
}) => {
  const { consultationId } = useParams<{ consultationId: string }>();
  const [showMethodModal, setShowMethodModal] = useState(false);
  const config = getConfig();
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const [
    confirmAsyncConsultation,
    { loading: isConfirmAsyncConsultationLoading },
  ] = useMutation<
    ConfirmAsyncConsultationMutation,
    ConfirmAsyncConsultationMutationVariables
  >(confirmAsyncConsultationMutation, {
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage: 'Unable to select text-based chat. Please try again.',
          description: 'Follow-up/review consult choice page',
        }),
      });
    },
  });

  return (
    <>
      <div className="flex flex-col h-full space-y-6 text-center">
        <Typography size="lg" isBold>
          <FormattedMessage defaultMessage="How do you want to be contacted for your consult?" />
        </Typography>
        <div className="space-y-3">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will use this method to get in touch with you."
              values={{ isGb: config.countryCode === 'GB' }}
            />
          </Typography>
        </div>
        {config.countryCode === 'AU' && (
          <div className="space-y-3">
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="We will do our best to accommodate your preference. In busy periods it may not always be possible." />
            </Typography>
          </div>
        )}
        <div>
          <LinkButton
            onClick={() => setShowMethodModal(true)}
            iconLeft={<FaRegQuestionCircle />}
            palette={linkButtonPalette}
          >
            <Typography size="small-text" isBold>
              <FormattedMessage defaultMessage="Which method will work best for me?" />
            </Typography>
          </LinkButton>
        </div>
        <div
          className={clsx('flex space-y-2', {
            'flex-col': config.countryCode === 'AU',
            'flex-col-reverse space-y-reverse': config.countryCode !== 'AU',
          })}
        >
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            isDisabled={isConfirmAsyncConsultationLoading}
            onClick={async () => {
              const resp = await confirmAsyncConsultation({
                variables: {
                  input: {
                    consultationId,
                  },
                },
              });

              if (resp.data?.confirmAsyncConsultation) {
                onTextSelected();
              }
            }}
          >
            <FormattedMessage defaultMessage="Text-based chat" />
          </Button>
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={onPhoneSelected}
          >
            <FormattedMessage defaultMessage="Phone call" />
          </Button>
        </div>
      </div>
      <Modal
        isOpen={showMethodModal}
        title="Which method will work best for me?"
        onClose={() => setShowMethodModal(false)}
      >
        <div className="flex flex-col gap-y-6">
          {config.countryCode === 'AU' ? (
            <>
              <div className="flex flex-col gap-y-2">
                <Typography size="medium-paragraph" isBold>
                  <FormattedMessage defaultMessage="Text-based chat" />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="Some people prefer text-based chat for its convenience/accessibility. It’s often a faster way to get in touch with your practitioner." />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="It is also a more suitable option for patients who have a hearing impairment or who speak English as a second language." />
                </Typography>
              </div>
              <div className="flex flex-col gap-y-2">
                <Typography size="medium-paragraph" isBold>
                  <FormattedMessage defaultMessage="Phone call" />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="Others find it easier to talk to a practitioner on a phone call." />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="If you choose to be contacted by phone call, you’ll get to select a date and time window for the call that works for you." />
                </Typography>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-y-2">
                <Typography size="medium-paragraph" isBold>
                  <FormattedMessage defaultMessage="Phone call" />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="Some people find it easier to talk to a {isGb, select, true {prescriber} other {practitioner}} on a phone call."
                    values={{ isGb: config.countryCode === 'GB' }}
                  />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="If you choose to be contacted by phone call, you’ll get to select a date and time window for the call that works for you." />
                </Typography>
              </div>
              <div className="flex flex-col gap-y-2">
                <Typography size="medium-paragraph" isBold>
                  <FormattedMessage defaultMessage="Text-based chat" />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage defaultMessage="Other people prefer text-based chat for its convenience/accessibility. It may be a more comfortable option for patients who have a hearing impairment or who speak English as a second language." />
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="If you choose to be contacted by text-based chat, a {isGb, select, true {prescriber} other {practitioner}} will send you a message soon."
                    values={{ isGb: config.countryCode === 'GB' }}
                  />
                </Typography>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
