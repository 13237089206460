import { Button, Card, Typography } from '@eucalyptusvc/design-system';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

export type SubscriptionAlertCardProps = {
  title: string;
  alertIcon?: ReactElement;
  bodyText: string;
  confirmButtonText: string;
  confirmUrl: string;
};

export const SubscriptionAlertCard: React.FC<SubscriptionAlertCardProps> = ({
  title,
  alertIcon,
  bodyText,
  confirmButtonText,
  confirmUrl,
}) => {
  const history = useHistory();
  return (
    <Card palette="alternate">
      <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 space-x-0 md:space-x-3">
        {alertIcon && <span>{alertIcon}</span>}
        <div className="m-2">
          <Typography size="medium-paragraph" isBold>
            {title}
          </Typography>
          <Typography size="paragraph">{bodyText}</Typography>
        </div>
        <div className="flex flex-none">
          <Button
            palette="alternate"
            level="primary"
            onClick={() => history.push(confirmUrl)}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Card>
  );
};
