import React, { useState } from 'react';
import {
  OtcScheduleCadence,
  Scalars,
  ExperimentPaymentPlanVariant,
  Maybe,
} from '@customer-frontend/graphql-types';
import { Typography, RadioButton, Button } from '@eucalyptusvc/design-system';
import { QuantityInput } from '@customer-frontend/order';
import { ReactComponent as ShoppingTag } from './assets/shopping-tag.svg';
import { ReactComponent as CalendarTick } from './assets/calendar-tick.svg';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  calculatePaymentPlanChargeAmount,
  formatCentsToCurrency,
} from '../logic';
import { getConfig } from '@customer-frontend/config';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';

interface CadenceSelectionProduct {
  name: string;
  photo?: Maybe<{
    url: string;
  }>;
  variants: Array<{
    price: number;
    inventory?: Maybe<{
      sku?: string | null | undefined;
    }>;
  }>;
}

interface ICadenceOption {
  value: OtcScheduleCadence;
  label: MessageDescriptor;
  description?: MessageDescriptor;
}

interface ExperimentPaymentPlan {
  paymentPlan: ExperimentPaymentPlanVariant;
  discountPercentage: number;
  cadence: string;
}

const messages = defineMessages({
  recurringLabel: {
    defaultMessage: 'Add to every order',
  },
  recurringDescription: {
    defaultMessage:
      "We'll automatically add it every time we ship your prescription. You can remove this item any time.",
  },
  oneTimeLabel: {
    defaultMessage: 'One-time purchase',
  },
  experimentRecurringLabel: {
    defaultMessage: 'Add to every upcoming order',
  },
  experimentRecurringDescription: {
    defaultMessage:
      'Ships every 2 months. Sent with your personalised treatment refill.',
  },
});

const CADENCE_OPTIONS: ICadenceOption[] = [
  {
    value: 'RECURRING',
    label: messages.recurringLabel,
    description: messages.recurringDescription,
  },
  {
    value: 'ONE_TIME',
    label: messages.oneTimeLabel,
  },
];

const OTC_CART_EXPERIMENT_CADENCE_OPTIONS: ICadenceOption[] = [
  {
    value: 'RECURRING',
    label: messages.experimentRecurringLabel,
    description: messages.experimentRecurringDescription,
  },
  {
    value: 'ONE_TIME',
    label: messages.oneTimeLabel,
  },
];

export const isOfOtcScheduleCadence = (
  keyInput: string,
): keyInput is OtcScheduleCadence => {
  return ['ONE_TIME', 'RECURRING'].includes(keyInput);
};

const getOptionLabel = (
  label: string,
  cadenceValue: OtcScheduleCadence,
  recurringDiscountPercent?: number,
): React.ReactElement | string => {
  if (cadenceValue === 'RECURRING' && recurringDiscountPercent) {
    return (
      <div className="flex items-center space-x-2">
        <div>{label}</div>
        <div className="text-green-600 flex items-center space-x-1 md:space-x-2">
          <ShoppingTag />
          <p>
            <FormattedMessage
              defaultMessage="{recurringDiscountPercent}% off"
              description="Label showing the percentage of the discount applied"
              values={{ recurringDiscountPercent }}
            />
          </p>
        </div>
      </div>
    );
  } else {
    return label;
  }
};

interface CadenceSelectionProps {
  product?: CadenceSelectionProduct;
  isLoading?: boolean;
  confirmText?: string;
  recurringDiscountPercent?: number;
  onComplete: (cadence: OtcScheduleCadence, quantity?: number) => void;
  experimentPaymentPlan?: ExperimentPaymentPlan;
  enableQuantitySelect?: boolean;
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  additionalInformation?: React.ReactNode;
}

const calculatePriceOnExperimentPaymentPlan = (
  variantPrice: number,
  cadence: OtcScheduleCadence,
  experimentPaymentPlan?: ExperimentPaymentPlan,
):
  | { totalPrice: number; amountSaved: number; perOrder: number }
  | undefined => {
  if (!experimentPaymentPlan || !variantPrice || cadence !== 'RECURRING') {
    return undefined;
  }

  return calculatePaymentPlanChargeAmount({
    recurringCharge: variantPrice,
    oneTimeCharge: 0,
    experimentPaymentPlan: experimentPaymentPlan,
  });
};

const ProductImage = ({
  productUrl,
  productName,
}: {
  productUrl?: string;
  productName?: string;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <img
      className="w-20 h-auto rounded-md mr-4"
      src={productUrl}
      alt={formatMessage(
        {
          defaultMessage: '{productName} product',
          description: '',
        },
        { productName },
      )}
    />
  );
};

const ProductNamePriceQuantity = ({
  enableQuantitySelect,
  product,
  experimentPaymentPlan,
  quantity,
  onQuantityChange,
  shownVariant,
}: {
  enableQuantitySelect: boolean;
  product?: CadenceSelectionProduct;
  experimentPaymentPlan?: ExperimentPaymentPlan;
  quantity: number;
  onQuantityChange: (quantity: number) => void;
  shownVariant?: CadenceSelectionProduct['variants'][0];
}): React.ReactElement => {
  // VARIATION
  if (enableQuantitySelect) {
    return (
      <div className="space-y-4 w-full">
        <Typography size="md" isBold>
          <FormattedMessage defaultMessage="Quantity and frequency" />
        </Typography>
        <div className="flex items-center w-full">
          <ProductImage
            productName={product?.name}
            productUrl={product?.photo?.url}
          />
          <div className="flex flex-col w-full gap-y-3">
            <div className="flex justify-between w-full">
              <Typography size="xs" isBold>
                {product?.name}
              </Typography>
              {!experimentPaymentPlan && (
                <div className="w-12 text-right whitespace-nowrap">
                  <Typography size="xs">
                    {formatCentsToCurrency(shownVariant?.price || 0)}
                  </Typography>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center w-full">
              <QuantityInput value={quantity} onChange={onQuantityChange} />
              <span className="text-right whitespace-nowrap">
                <Typography size="paragraph" isBold>
                  <FormattedMessage
                    defaultMessage="Total"
                    description="Total for the order"
                  />
                </Typography>
                <Typography size="paragraph" isBold>
                  {formatCentsToCurrency((shownVariant?.price || 0) * quantity)}
                </Typography>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // CONTROL
  return (
    <>
      <div className="flex items-center">
        <ProductImage productUrl={product?.photo?.url} />
        <div className="space-y-2">
          <Typography size="md" isBold inheritColor>
            {product?.name}
          </Typography>
          {!experimentPaymentPlan && (
            <Typography size="md" element="p" isBold inheritColor>
              {formatCentsToCurrency(shownVariant?.price || 0)}
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export const OtcCadenceSelect = ({
  product,
  isLoading,
  onComplete,
  recurringDiscountPercent,
  confirmText = 'Add to order',
  experimentPaymentPlan,
  enableQuantitySelect = false,
  nextPaymentDate,
  additionalInformation,
}: CadenceSelectionProps): React.ReactElement => {
  const config = getConfig();
  const shownVariant = product?.variants?.[0];
  const [cadence, setCadence] = useState<OtcScheduleCadence>('RECURRING');
  const [quantity, setQuantity] = useState<number>(1);
  const { formatMessage } = useIntl();

  const handleCadenceChange = (value: string): void => {
    if (isOfOtcScheduleCadence(value)) {
      setCadence(value);
    } else {
      throw Error(`Unable to convert ${value} as OtcScheduleCadence`);
    }
  };

  let cadenceOptionsCopy = enableQuantitySelect
    ? OTC_CART_EXPERIMENT_CADENCE_OPTIONS
    : CADENCE_OPTIONS;
  const featureFlagClient = useFeatureFlagClient();

  const disabledRecurringOrdersSkus = featureFlagClient.getJson<string[]>(
    'DISABLE_OTC_ADDONS_RECURRING_ORDERS',
    { defaultValue: [] },
  );

  const variantSkus =
    product?.variants.reduce(
      (acc: string[], v) =>
        v?.inventory?.sku ? acc.concat(v.inventory.sku) : acc,
      [],
    ) || [];
  if (
    disabledRecurringOrdersSkus &&
    variantSkus.some((vsku) => disabledRecurringOrdersSkus.includes(vsku))
  ) {
    cadenceOptionsCopy = cadenceOptionsCopy.filter(
      (o) => o.value !== 'RECURRING',
    );
  }

  return (
    <div className="pt-5 space-y-6">
      <div className="flex items-start justify-between sm:max-h-full w-full">
        <ProductNamePriceQuantity
          enableQuantitySelect={enableQuantitySelect}
          product={product}
          experimentPaymentPlan={experimentPaymentPlan}
          quantity={quantity}
          onQuantityChange={(quantity: number) => setQuantity(quantity)}
          shownVariant={shownVariant}
        />
      </div>
      {config.brand === 'software' && nextPaymentDate && (
        <div className="flex items-center gap-2">
          <CalendarTick />
          <Typography size="paragraph">
            <FormattedMessage
              defaultMessage="Ships on: <a>{formattedDate}</a>"
              values={{
                formattedDate: formatDate(
                  mapBrandToAdaptersBrand(config.brand),
                  nextPaymentDate,
                  { dateStyle: 'long' },
                ),
                a: (chunks) => (
                  <Typography size="paragraph" element="span" isBold>
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </div>
      )}
      <div className="space-y-4">
        {cadenceOptionsCopy
          .map((option) => {
            const translatedDescription = option.description
              ? formatMessage(option.description)
              : '';
            const priceOnExperimentPaymentPlan =
              calculatePriceOnExperimentPaymentPlan(
                shownVariant?.price ?? 0,
                option.value,
                experimentPaymentPlan,
              );

            return {
              ...option,
              totalPrice:
                priceOnExperimentPaymentPlan?.totalPrice ??
                shownVariant?.price ??
                0,
              amountSaved: priceOnExperimentPaymentPlan?.amountSaved,
              monthlyPrice: priceOnExperimentPaymentPlan?.perOrder,
              description:
                experimentPaymentPlan && option.value === 'RECURRING'
                  ? formatMessage({
                      defaultMessage:
                        "We'll automatically add it every time we ship your prescription.",
                    })
                  : translatedDescription,
            };
          })
          .map(
            ({
              label,
              value,
              description,
              totalPrice,
              amountSaved,
              monthlyPrice,
            }) => (
              <div key={value} className="space-y-2">
                <RadioButton
                  name={value}
                  label={getOptionLabel(
                    formatMessage(label),
                    value,
                    recurringDiscountPercent,
                  )}
                  value={value}
                  isSelected={cadence === value}
                  onChange={(e, value): void => handleCadenceChange(value)}
                />
                {experimentPaymentPlan && (
                  <div className="flex flex-row space-x-1">
                    <Typography size="paragraph" isBold>
                      {formatCentsToCurrency(totalPrice)}
                    </Typography>
                    <div className="text-secondary-navy-200">
                      {monthlyPrice && amountSaved && (
                        <Typography size="paragraph">
                          <FormattedMessage
                            defaultMessage="({formattedMonthlyPrice}/order, save {formattedAmountSaved})"
                            values={{
                              formattedMonthlyPrice:
                                formatCentsToCurrency(monthlyPrice),
                              formattedAmountSaved:
                                formatCentsToCurrency(amountSaved),
                            }}
                          />
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
                {description && (
                  <Typography size="paragraph" inheritColor>
                    {description}
                  </Typography>
                )}
              </div>
            ),
          )}
      </div>
      {additionalInformation && additionalInformation}
      <Button
        isFullWidth
        palette="alternate"
        isLoading={isLoading}
        onClick={() => {
          onComplete(cadence, quantity);
        }}
      >
        {confirmText}
      </Button>
    </div>
  );
};
