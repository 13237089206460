import React from 'react';
import { useForm, Controller, ControllerRenderProps } from 'react-hook-form';
import { useSubmitPatientPharmacistQuestion } from '@customer-frontend/services';
import { FeatureFlagBoolean } from '@customer-frontend/feature-flags';
import {
  Button,
  TextArea,
  Typography,
  RadioButtonGroup,
} from '@eucalyptusvc/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

enum ContactMethod {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export const PharmacistQuestion = ({
  treatmentId,
  questionSent,
  setQuestionSent,
  isOzempicProduct,
}: {
  treatmentId: string;
  questionSent: boolean;
  setQuestionSent: (questionSent: boolean) => void;
  isOzempicProduct: boolean;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  const { register, handleSubmit, watch, control } = useForm<{
    pharmacistQuestion: string;
    contactMethod: ContactMethod | null;
  }>({
    shouldUnregister: false,
    defaultValues: { contactMethod: null },
  });

  const handleSetQuestionSent = (questionSent: boolean): void => {
    setQuestionSent(questionSent);
  };

  const [submitPharmacyQuestionMutation, { loading }] =
    useSubmitPatientPharmacistQuestion({
      onCompleted: () => {
        handleSetQuestionSent(true);
      },
      onError: () => {
        handleSetQuestionSent(false);
      },
    });

  const { pharmacistQuestion, contactMethod } = watch();
  const isSendDisabled = !pharmacistQuestion || !contactMethod;

  const handleFormSubmit = handleSubmit(async (data) => {
    submitPharmacyQuestionMutation({
      variables: {
        treatmentId,
        contactMethod: data.contactMethod,
        messageText: data.pharmacistQuestion,
      },
    });
  });

  return (
    <form
      onSubmit={handleFormSubmit}
      className="space-y-6 text-secondary-navy-300"
    >
      {!questionSent && (
        <div className="space-y-3">
          <Typography size="medium-paragraph" isBold>
            <FormattedMessage
              defaultMessage="Any questions about your medication? Ask {isOzempicProduct, select, true {our Medical Support team} other {your pharmacist}}."
              values={{ isOzempicProduct }}
            />
          </Typography>
          {isOzempicProduct ? (
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Pilot's Medical Support team of nurses and pharmacists can answer any questions you have about the medication. The team will get back to your questions via email."
                description="Text informing the user that Pilot's Medical Support will respond to their questions via email"
              />
            </Typography>
          ) : (
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="Please note that the pharmacist will receive info about your allergies, medical conditions and medications to help answer your question. " />
            </Typography>
          )}
          <FeatureFlagBoolean flagKey="PT_866_PHARMACY_COPY">
            <FormattedMessage defaultMessage="If you do not have a question to ask, but would like to receive additional counselling via a phone call from our pharmacists, simply type “yes” in the free text field below." />
          </FeatureFlagBoolean>
          <TextArea
            name="pharmacistQuestion"
            ref={register}
            rows={3}
            placeholder={formatMessage({
              defaultMessage: 'Ask your pharmacist about your medication"',
            })}
          />
          {!isOzempicProduct && (
            <>
              <Typography size="medium-paragraph" isBold>
                <FormattedMessage
                  defaultMessage="I want to receive the response by"
                  description="text on a screen where the customer can ask the pharmacist questions how they will get their response email, etc"
                />
              </Typography>
              <div className="space-y-1">
                <Controller
                  name="contactMethod"
                  control={control}
                  render={({
                    onChange,
                    value,
                    name,
                    ref,
                  }: ControllerRenderProps<Record<string, unknown>>) => (
                    <RadioButtonGroup
                      name={name}
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      options={[
                        {
                          label: formatMessage({
                            defaultMessage: 'Email',
                            description:
                              'option for select method of communication',
                          }),
                          value: ContactMethod.EMAIL,
                        },
                        {
                          label: formatMessage({
                            defaultMessage: 'Phone',
                            description:
                              'option for select method of communication',
                          }),
                          value: ContactMethod.PHONE,
                        },
                      ]}
                    />
                  )}
                />
              </div>
            </>
          )}
          <div className="mt-4 flex justify-end">
            <Button
              isDisabled={isSendDisabled || loading}
              isLoading={loading}
              isSubmit
              level="secondary"
              isFullWidth
            >
              <FormattedMessage defaultMessage="Send question to pharmacist" />
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};
