import { gql, useQuery } from '@apollo/client';
import { useShowZendeskWidget } from '@customer-frontend/consultation';
import { useEventService } from '@customer-frontend/events';
import {
  WeightResetQuizApplicationQuery,
  WeightResetQuizApplicationQueryVariables,
} from '@customer-frontend/graphql-types';
import { useStartQuizApplication } from '@customer-frontend/services';
import {
  Button,
  ButtonPalette,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactElement, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

type WeightResetProps = {
  quizIncompleteIllustration: string;
  quizCompleteIllustration: string;
  quizApplicationId: string;
  buttonPallete?: ButtonPalette;
  startQuizRoute: (quizApplicationId: string) => string;
};

export const WeightReset = ({
  quizIncompleteIllustration,
  quizCompleteIllustration,
  quizApplicationId,
  buttonPallete,
  startQuizRoute,
}: WeightResetProps): ReactElement => {
  const { data: quizApplicationData, loading: quizApplicationLoading } =
    useQuery<
      WeightResetQuizApplicationQuery,
      WeightResetQuizApplicationQueryVariables
    >(
      gql`
        query WeightResetQuizApplication($id: String!) {
          getQuizApplication(id: $id) {
            id
            problemType
            submittedAt
          }
        }
      `,
      {
        variables: {
          id: quizApplicationId,
        },
      },
    );

  const startQuizApplication = useStartQuizApplication();
  const history = useHistory();
  const [isLoadingNewQuizApplication, setIsLoadingNewQuizApplication] =
    useState(false);
  const events = useEventService();
  const screenLoadEventSent = useRef(false);

  useShowZendeskWidget({
    problemType: quizApplicationData?.getQuizApplication?.problemType,
  });

  const startNewApplication = async () => {
    setIsLoadingNewQuizApplication(true);
    const quizApplication = await startQuizApplication('WEIGHT_LOSS');
    history.replace(startQuizRoute(quizApplication.id));
    setIsLoadingNewQuizApplication(false);
  };

  if (quizApplicationLoading) {
    return (
      <div className="flex justify-center p-5 text-primary-500">
        <LoadingSpinner />
      </div>
    );
  }

  const qaData = quizApplicationData?.getQuizApplication;
  if (!screenLoadEventSent.current && qaData) {
    screenLoadEventSent.current = true;
    events.quizResetViewedEvent({
      oldQuizApplicationId: quizApplicationId,
      hadConsult: qaData.submittedAt !== null,
    });
  }

  return (
    <>
      <div className="justify-center mb-6 mx-auto grid grid-cols-3 md:grid-cols-12">
        <img
          className="col-start-2 mx-auto md:col-span-2 md:col-start-6"
          src={
            quizApplicationData?.getQuizApplication?.submittedAt
              ? quizCompleteIllustration
              : quizIncompleteIllustration
          }
        />
      </div>

      <div className="flex flex-col mt-4 gap-4">
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="Welcome back!" />
        </Typography>

        {quizApplicationData?.getQuizApplication?.submittedAt ? (
          <>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="You completed the quiz quite a while ago." />
            </Typography>

            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="Please restart it to ensure your health information is up-to-date. It should only take a few minutes." />
            </Typography>
          </>
        ) : (
          <>
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="It’s been a while since your last visit. " />
            </Typography>

            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="⁠Please restart your quiz to ensure your health information is up-to-date. It should only take a few minutes." />
            </Typography>
          </>
        )}

        <Button
          palette={buttonPallete}
          isFullWidth
          isLoading={isLoadingNewQuizApplication}
          onClick={startNewApplication}
          eventElementName="quizResetButton"
          eventElementAttributes={{ quizApplicationId }}
        >
          <FormattedMessage defaultMessage="Restart quiz" />
        </Button>
      </div>
    </>
  );
};
