export type BooleanFlag = {
  flagKey: string;
  defaultValue: boolean;
};

export type MultivariateEnrolmentStatus =
  | 'not-enrolled'
  | 'control'
  | 'variation';

export type FlagValue = boolean | number | string | object | FlagValue[];

export type JsonFlagConfigOptions<T> = {
  disableExposureTracking?: boolean;
  defaultValue: T;
};

/**
 * Dictionary of flag keys to BooleanFlag.
 *
 * @example
 * const BooleanFeatureFlags = {
 *     SHOW_MEET_THE_INGREDIENTS: {
 *         flagKey: 'software_consultationprofile_ingredient_information',
 *         defaultValue: false,
 *     } as BooleanFlag,
 * };
 */
export const BooleanFeatureFlags = {
  ALLOW_PAUSING_PURCHASES: {
    flagKey: 'allow-pausing-purchases',
    defaultValue: false,
  },
  REDO_QUIZ: {
    flagKey: 'software_redo_quiz',
    defaultValue: false,
  },
  KIN_REDO_QUIZ: {
    flagKey: 'kin_redo_quiz',
    defaultValue: false,
  },
  EUC_PSAT: {
    flagKey: 'euc_psat',
    defaultValue: false,
  },
  PT_866_PHARMACY_COPY: {
    flagKey: 'pt-866-pharmacy-copy-call',
    defaultValue: false,
  },
  ORDER_PAID_DISCOUNTS: {
    flagKey: 'software_enable_op_discounts',
    defaultValue: false,
  },
  CONSULT_DISCOUNTS: {
    flagKey: 'enable_consult_discounts',
    defaultValue: false,
  },
  PILOT_ED_WEIGHT_RECOMMENDATION: {
    flagKey: 'pilot_weight_ed_doctor_recommendation',
    defaultValue: false,
  },
  ENABLE_TREATMENT_PLAN_DISCOUNT_FIELDS: {
    flagKey: 'enable_treatment_plan_discount_fields',
    defaultValue: false,
  },
  ENABLE_TREATMENT_REFILL_COOLDOWN: {
    flagKey: 'enable_treatment_refill_cooldown',
    defaultValue: false,
  },
  SHOW_SUBSCRIPTIONS: {
    flagKey: 'show_subscriptions',
    defaultValue: false,
  },
  OFFLINE_FOR_MAINTENANCE: {
    flagKey: 'offline-for-maintenance',
    defaultValue: false,
  },
  ENABLE_SUBSCRIPTIONS_V2_OPT_IN_MIGRATION: {
    flagKey: 'enable_subscriptions_v2_opt_in_migration',
    defaultValue: false,
  },
  SAXENDA_SHIPPING_DELAYS: {
    flagKey: 'saxenda-shipping-delays',
    defaultValue: false,
  },
  AUTO_ADD_DISCOUNTED_PRODUCTS: {
    flagKey: 'auto_add_discounted_products',
    defaultValue: false,
  },
  WEIGHT_SIDE_EFFECTS_FAQ: {
    flagKey: 'weight_side_effects_faq',
    defaultValue: false,
  },
  WEIGHT_SUPPORT_QUIZ: {
    flagKey: 'weight_support_quiz',
    defaultValue: false,
  },
  PILOT_PRICING_BATCHING_V2: {
    flagKey: 'pilot-pricing-batching-v2',
    defaultValue: false,
  },
  PILOT_PRICING_BATCHING_V3: {
    flagKey: 'pilot-pricing-batching-v3',
    defaultValue: false,
  },
  OTC_CART_DISCOUNTS: {
    flagKey: 'otc_cart_discounts',
    defaultValue: false,
  },
  PERSONALISED_MARKETING_CONSENT: {
    flagKey: 'personalised_marketing_consent',
    defaultValue: false,
  },
  SOFTWARE_SKIN_ONBOARDING_QUIZ: {
    flagKey: 'software_skin_onboarding_quiz',
    defaultValue: false,
  },
  SOFTWARE_SKIN_CHECK_IN_QUIZ: {
    flagKey: 'skin_check_in_quiz',
    defaultValue: false,
  },
  TREATMENT_COMMENCED_DATE: {
    flagKey: 'treatment_commenced_date',
    defaultValue: false,
  },
  SOFTWARE_INSIDERS_WIDGET: {
    flagKey: 'software-insiders-home-widget',
    defaultValue: false,
  },
  // This feature flag needs to be enabled brand by brand
  ENABLE_CUSTOMER_FRONTEND_STRIPE_PAYMENT_INTENT: {
    flagKey: 'enable_customer_frontend_stripe_payment_intent_api',
    defaultValue: false,
  },
  PREFERRED_NAME: {
    flagKey: 'innoweek-preferred-name',
    defaultValue: false,
  },
  WEGOVY_LAUNCH_CONTENT: {
    flagKey: 'wegovy_launch_content',
    defaultValue: false,
  },
  JUNIPER_NOURISH_SHAKES_ADDONS_SECTION: {
    flagKey: 'nourish-shakes-addons-section',
    defaultValue: false,
  },
  DELIVERY_INSTRUCTIONS_INPUT: {
    flagKey: 'delivery-instructions-input',
    defaultValue: false,
  },
  MOBILE_APP_PROMPT: {
    flagKey: 'juniper-mobile-op-confirm-prompt',
    defaultValue: false,
  },
  MEDICATION_SHORTAGE_PHASE_PHENTOP: {
    flagKey: 'medication-shortage-phase-phentop',
    defaultValue: false,
  },
  MEDICATION_SHORTAGE_PHASE_PHENTOP_WEBONLY: {
    flagKey: 'medication-shortage-phase-phentop-webonly',
    defaultValue: false,
  },
  MEDICATION_SHORTAGE_PHASE_OZEMPIC: {
    flagKey: 'medication-shortage-phase-ozempic',
    defaultValue: false,
  },
  SPLIT_UPFRONT_PAYMENT_PAGE: {
    flagKey: 'split_upfront_payment_page',
    defaultValue: false,
  },
  JUNIPER_AU_AUTOMATED_PHARMACY_INVOICES: {
    flagKey: 'juniper-au.automated-pharmacy-invoices',
    defaultValue: false,
  },
  ENABLE_OTC_ADDONS: {
    flagKey: 'enable-otc-addons',
    defaultValue: false,
  },
  JUNIPER_FLEXIPLANS_ENABLE_OVERRIDES: {
    flagKey: 'juniper.flexiplans.enable-overrides',
    defaultValue: false,
  },
  DISABLE_SIGNUP: {
    flagKey: 'disable-signup',
    defaultValue: false,
  },
  DEEMPHASIZE_DASHBOARD_LINK: {
    flagKey: 'deemphasize-dashboard-link',
    defaultValue: false,
  },
  MOBILE_DOWNLOAD_BANNER: {
    flagKey: 'mobile-download-top-banner',
    defaultValue: false,
  },
  JUNIPER_NEW_START_SIGNUP_PAGE: {
    flagKey: 'juniper-new-start-signup-page',
    defaultValue: false,
  },
  KS_WAITLIST_REDIRECT: {
    flagKey: 'ks_waitlist_redirect',
    defaultValue: false,
  },
  CFG_PRACTITIONER_BACKLOG_MESSAGING: {
    flagKey: 'cfg_practitioner_backlog_messaging',
    defaultValue: false,
  },
  FF_CONSULTATION_ORDER_PAYMENT_SPLIT_FLOW: {
    flagKey: 'ff-consultation-order-payment-split-flow',
    defaultValue: false,
  },
  FF_ADDRESS_VALIDATION_EXEMPTION: {
    flagKey: 'ff-address-validation-exemption',
    defaultValue: false,
  },
  ENABLE_FLEXIPLANS_OTC_PURCHASE: {
    flagKey: 'enable-flexiplans-otc-purchase',
    defaultValue: false,
  },
  FF_FLEXI_BRAND_TIER_SELECTION_ENABLED: {
    flagKey: 'ff_flexi_brand_tier_selection_enabled',
    defaultValue: false,
  },
  FF_MARKETING_CONSENT_UI_UPDATES: {
    flagKey: 'ff_marketing_consent_ui_updates',
    defaultValue: false,
  },
  XP_REMOVE_QUIZ_START_PAGE: {
    flagKey: 'xp_remove_quiz_start_page',
    defaultValue: false,
  },
  XP_REMOVE_WELCOME_PAGE: {
    flagKey: 'xp_remove_welcome_page',
    defaultValue: false,
  },
  FF_ALLOW_CUSTOMER_SYNC_GROUP_DELAY: {
    flagKey: 'ff_allow_customer_sync_group_delay',
    defaultValue: false,
  },
  FF_QUIZ_AUTO_RESET: {
    flagKey: 'ff_quiz_reset',
    defaultValue: false,
  },
  FF_PLAN_PAGE_PSG_TIMELINE_ENABLED: {
    flagKey: 'ff_plan_page_psg_timeline_enabled',
    defaultValue: false,
  },
  FF_CF_APPLY_MSD_ENABLED: {
    flagKey: 'ff_cf_apply_msd',
    defaultValue: false,
  },
  FF_ASYNC_CONSULTS_ENABLED: {
    flagKey: 'ff-async-consults-enabled',
    defaultValue: false,
  },
  FF_FORCE_FUR_CONSULTATIONS_TO_ASYNC_QUEUE: {
    flagKey: 'ff-force-fur-consultations-to-async-queue',
    defaultValue: false,
  },
  XP_INCLUSION_TAGS_UPDATES: {
    flagKey: 'xp-inclusion-tags-updates',
    defaultValue: false,
  },
} as const satisfies Record<string, BooleanFlag>;

export enum JsonFlags {
  HOW_IS_PRICE_DECIDED_BREAKDOWN_SKUS = 'how_is_price_decided_breakdown_skus',
  HIGH_PRICE_OZEMPIC_SKUS = 'high_price_ozempic_skus',
  LOW_PRICE_OZEMPIC_SKUS = 'low_price_ozempic_skus',
  EVERMED_CONFIG = 'evermed-config',
  DISABLE_OTC_ADDONS_RECURRING_ORDERS = 'disable-otc-addons-recurring-orders',
  CONSULTATION_FLOW_CONFIG = 'consultation_flow_config',
  LEGACY_SKUS_WITH_CHAT_ACCESS = 'legacy_skus_with_chat_access',
  CFG_MULTIPLE_MEDICATION_PREFERENCE_OPTIONS = 'cfg_multiple_medication_preference_options',
  FLEXI_BNPL_PROBLEM_TYPES = 'flexi-bnpl-problem-types',
  FLEXI_FUR_CONSULTATION_PROBLEM_TYPES = 'flexi-fur-consultation-problem-types',
  DISABLED_MEDICARE_COLLECTION_PROBLEM_TYPES = 'disabled_medicare_collection_problem_types',
  DISABLE_SKIP_MEDICARE_FORM = 'disable-skip-medicare-form',
  MOBILE_SHOP_SHAKES_ID_LIST = 'mobile_shop_shakes_shop_item_ids',
}

export type MultivariateFlag = {
  flagKey: string;
  defaultValue: MultivariateEnrolmentStatus;
};

export type CustomFlag = {
  flagKey: string;
  defaultValue: string;
  values: readonly string[];
};

/**
 * See @type {BooleanFeatureFlags} for usage.
 */
export const MultivariateFeatureFlags = {
  SUGGEST_TRET_WHERE_POSSIBLE: {
    flagKey: 'software_consultationProfile_showTretIfNotPregnant',
    defaultValue: 'not-enrolled',
  },
  ENABLE_MEDICARE_COLLECTION: {
    flagKey: 'enable_medicare_collection',
    defaultValue: 'not-enrolled',
  },
  HEALTH_COACHING_EXPERIMENT: {
    flagKey: 'health_coaching_experiment',
  } as MultivariateFlag,
  SOFTWARE_OTC_PERSONALISED_REGIMEN: {
    flagKey: 'software_otc_personalised_regimen_v2',
    defaultValue: 'not-enrolled',
  },
  EDUCATION_PLATFORM: {
    flagKey: 'education_platform',
    defaultValue: 'not-enrolled',
  },
  SOFTWARE_EDUCATION_WIDGET: {
    flagKey: 'software-education-platform-widget',
    defaultValue: 'not-enrolled',
  },
  INNOWEEK_DATA_PROTECTION_MODAL: {
    flagKey: 'innoweek-data-protection-modal',
    defaultValue: 'not-enrolled',
  },
  XP_CP_PAGE_COPY_UPDATES: {
    flagKey: 'xp-cp-page-copy-updates',
    defaultValue: 'not-enrolled',
  },
  XP_QUIZ_COMPLETE_LOADING_TIME: {
    flagKey: 'xp-quiz-complete-loading-time',
    defaultValue: 'not-enrolled',
  },
} as const satisfies Record<string, MultivariateFlag>;

export const CustomFeatureFlags = {
  XP_WEIGHT_LOSS_GRAPH: {
    flagKey: 'xp-weight-loss-graph',
    defaultValue: 'not-enrolled-control',
    values: [
      'not-enrolled-control',
      'control',
      'not-enrolled-a',
      'variation-a',
      'not-enrolled-b',
      'variation-b',
    ],
  },
} as const satisfies Record<string, CustomFlag>;

export type ExperimentWeightLossGraphValues =
  (typeof CustomFeatureFlags.XP_WEIGHT_LOSS_GRAPH.values)[number];

export type FlagConfigOptions = {
  disableExposureTracking?: boolean;
  additionalAttributes?: object;
};

export type MultivariateFlagKey = keyof typeof MultivariateFeatureFlags;
export type BooleanFlagKey = keyof typeof BooleanFeatureFlags;
export type JsonFlagKey = keyof typeof JsonFlags;
export type CustomFlagKey = keyof typeof CustomFeatureFlags;
