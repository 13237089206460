import React from 'react';
import {
  Button,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import {
  ChatPageQuery,
  ChatPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  MessageTimestamp as DefaultMessageTimestamp,
  Thread,
  Window,
  DateSeparatorProps,
  SendButtonProps,
  MessageTimestampProps,
  MessageSimple,
  MessageUIComponentProps,
  Avatar as DefaultAvatar,
  AvatarProps,
  useChannelStateContext,
  UnreadMessagesSeparatorProps,
} from 'stream-chat-react';
import { ReactComponent as AttachIcon } from './assets/attach.svg';
import { ReactComponent as SendIcon } from './assets/left-arrow-circle.svg';
import { useQueryParam } from '../utils/hooks/use-query-params';
import { DoctorInfo, DoctorInfoFragment } from './doctor-info';
import { gql, useQuery } from '@apollo/client';
import { MessageStatus } from './message-status';
import { useHistory, useParams } from 'react-router-dom';
import { getConfig } from '@customer-frontend/config';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { UnassignedDoctorBanner } from './unassigned-doctor-banner';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { useEucChatContext } from '@customer-frontend/chat-provider';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { useShowZendeskWidget } from '@customer-frontend/consultation';

const pageQuery = gql`
  query ChatPage($consultationId: String!) {
    consultation(id: $consultationId) {
      id
      doctor {
        ...DoctorInfo
      }
      chatThread {
        id
      }
    }
  }
  ${DoctorInfoFragment}
`;

const ChannelWindow = ({
  loadAtTop,
  onBack,
}: {
  loadAtTop: boolean;
  onBack: () => void;
}): React.ReactElement => {
  const { channel, loading } = useChannelStateContext();

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const isFrozen = channel.data?.frozen;

  return (
    <>
      <Window>
        <MessageList messageActions={[]} hideDeletedMessages />
        {isFrozen ? (
          <ChatCompleted onBack={onBack} />
        ) : (
          <MessageInput focus={!loadAtTop} grow maxRows={5} />
        )}
      </Window>
      <Thread />
    </>
  );
};

export const ConsultationChatTemplate: React.FC<{
  profileRoute: string;
  loadingStateTextColor: string;
  doctorInfoBrandColors?: { backgroundColor: string; borderColor: string };
}> = ({ profileRoute, loadingStateTextColor, doctorInfoBrandColors }) => {
  const { client, refreshClient } = useEucChatContext();
  const [hasRefreshedClient, setHasRefreshedClient] = React.useState(false);
  React.useEffect(() => {
    if (!client && !hasRefreshedClient) {
      refreshClient();
      setHasRefreshedClient(true);
    }
  }, [client, hasRefreshedClient, refreshClient]);
  const history = useHistory();
  const loadAtTop = useQueryParam('load-at-top');
  const { consultationId } = useParams<{ consultationId: string }>();
  const { data, loading } = useQuery<ChatPageQuery, ChatPageQueryVariables>(
    pageQuery,
    {
      variables: {
        consultationId,
      },
    },
  );

  useShowZendeskWidget({ disabled: true });

  if (loading || !client || !client.user) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const channelId = data?.consultation?.chatThread?.id;

  if (!channelId) {
    return <ChatError />;
  }

  const channel = client.getChannelById('messaging', channelId, {});
  const doctor = data?.consultation?.doctor;

  const handleBack = (): void => {
    history.replace(profileRoute);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none">
        {doctor ? (
          <DoctorInfo doctor={doctor} brandColors={doctorInfoBrandColors} />
        ) : (
          <UnassignedDoctorBanner />
        )}
      </div>
      <div className="flex-1 min-h-0 max-w-screen-sm mx-auto pb-4 mt-5">
        <Chat client={client}>
          <Channel
            channel={channel}
            Message={Message}
            DateSeparator={DateSeparator}
            MessageTimestamp={MessageTimestamp}
            Avatar={Avatar}
            FileUploadIcon={() => <AttachIcon />}
            SendButton={SendButton}
            MessageStatus={MessageStatus}
            UnreadMessagesSeparator={UnreadMessagesSeparator}
            EmptyStateIndicator={() => (
              <div className="py-8 px-5 flex flex-col items-center text-center space-y-4">
                <LoadingSpinner />
                <Typography
                  size="medium-paragraph"
                  color={loadingStateTextColor}
                >
                  <FormattedMessage
                    description="Loading header while chat messages load"
                    defaultMessage="Chat is loading. Please refresh after a few seconds if you don’t see a message."
                  />
                </Typography>
              </div>
            )}
          >
            <ChannelWindow loadAtTop={!!loadAtTop} onBack={handleBack} />
          </Channel>
        </Chat>
      </div>
    </div>
  );
};

const DateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
  const config = getConfig();

  return (
    <div className="str-chat__date-separator">
      <div className="str-chat__date-separator-date m-auto">
        {formatDate(mapBrandToAdaptersBrand(config.brand), date, {
          month: 'long',
          day: 'numeric',
        })}
      </div>
    </div>
  );
};

const UnreadMessagesSeparator: React.FC<UnreadMessagesSeparatorProps> = () => {
  return (
    <div className="str-chat__unread-messages-separator str-chat-unread-message-separator-text">
      <FormattedMessage
        description="the text to display when having unread messages"
        defaultMessage="unread messages"
      />
    </div>
  );
};

const Message: React.FC<MessageUIComponentProps> = (props) => (
  <MessageSimple {...props} isReactionEnabled={false} />
);

const MessageTimestamp: React.FC<MessageTimestampProps> = (props) => (
  <DefaultMessageTimestamp {...props} calendar={false} />
);

const Avatar: React.FC<AvatarProps> = (props) => (
  <DefaultAvatar {...props} size={24} />
);

const SendButton: React.FC<SendButtonProps> = ({
  sendMessage,
  ...rest
}: SendButtonProps) => (
  <button
    className="str-chat__send-button"
    onClick={sendMessage}
    type="button"
    {...rest}
  >
    <SendIcon />
  </button>
);

const ChatError: React.FC = () => (
  <div className="text-center space-y-1">
    <Typography size="md" element="h1">
      <FormattedMessage
        description="Error header shown when patient does not have a chat thread"
        defaultMessage="Oops! There's been a problem starting your chat"
      />
    </Typography>
    <Typography size="medium-paragraph" inheritColor>
      <FormattedMessage
        description="Paragraph asking the patient to contact support"
        defaultMessage="Please contact support to help get you get going again."
      />
    </Typography>
  </div>
);

const ChatCompleted: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const config = getConfig();

  return (
    <div className="space-x-2 flex items-center justify-between w-full px-4 md:p-0">
      <Typography size="medium-paragraph" inheritColor>
        <FormattedMessage
          description="Header shown when a chat is completed"
          defaultMessage="Your consultation has completed"
        />
      </Typography>
      <Button onClick={onBack} palette={getPrimaryButtonPalette(config.brand)}>
        <FormattedMessage defaultMessage="Back to profile" />
      </Button>
    </div>
  );
};
