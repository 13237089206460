import { button } from './button';
import { ImageUpload } from './image-upload';
import {
  getAvatarComponent,
  getFormComponents,
  getInputComponents,
  getSelectStyles,
  getToastComponent,
  getWithFallbacks,
  getOptionComponents,
  getModalStyles,
  getSpinnerComponent,
  getCardStyles,
  getDividerStyles,
  getDrawerStyles,
  getAccordionStyle,
  getNavTabsComponent,
} from '../../shared';
import { colors } from '../palette';
import { heading, text } from './typography';

export const components = {
  Text: text,
  ImageUpload,
  Button: button,
  Heading: heading,
  Accordion: getAccordionStyle({
    colors: {
      icon: colors.secondary.electric[200],
      text: colors.primary[600],
      border: colors.primary[200],
      focusColor: colors.tertiary.lavender,
    },
  }),
  Modal: getModalStyles({
    colors: {
      closeButtonColor: colors.primary[600],
      closeButtonHoverBgColor: colors.primary[100],
      closeButtonFocusColor: colors.tertiary.lavender,
      text: colors.primary[600],
    },
  }),
  Card: getCardStyles({
    colors: {
      font: colors.primary[500],
      backgroundAlternate: colors.secondary.cream[100],
    },
    border: {
      style: '1px solid',
      borderRadius: '8px',
      borderColor: 'transparent',
    },
    boxShadow:
      '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.1)',
  }),
  Divider: getDividerStyles({
    color: colors.primary[500],
    alternateColor: colors.primary[300],
  }),
  ...getFormComponents({
    colors: {
      container: colors.primary[500],
    },
  }),
  Drawer: getDrawerStyles({
    colors: { bg: colors.secondary.cream[100] },
  }),
  ...getInputComponents({
    colors: {
      border: colors.primary[500],
      borderFocused: colors.primary[500],
      shadow: colors.primary[200],
      bg: colors.neutral.white,
    },
    font: getWithFallbacks('Castledown'),
  }),
  ...getOptionComponents({
    boxShadowWidth: '0px',
    colors: {
      textColor: colors.primary[600],
      border: {
        default: colors.primary[300],
        focus: colors.primary[500],
        active: colors.primary[500],
      },
      background: colors.primary[100],
      focusColor: colors.primary[200],
      iconBackground: {
        checked: colors.primary[500],
        hover: colors.primary[100],
      },
      iconBorder: {
        default: colors.primary[300],
        focus: colors.primary[500],
        active: colors.primary[500],
      },
    },
  }),
  ...getAvatarComponent({
    font: getWithFallbacks('Castledown'),
    color: {
      default: colors.secondary.cream[100],
      alternate: colors.primary[500],
    },
    bgColor: {
      default: colors.primary[500],
      alternate: colors.primary[100],
    },
  }),
  Select: getSelectStyles({
    colors: {
      border: colors.primary[500],
      focus: colors.primary[200],
      icon: colors.secondary.electric[100],
    },
    borderRadius: '4px',
  }),
  ...getToastComponent({ borderRadius: '0.25rem' }),
  ...getSpinnerComponent({ color: colors.primary[600] }),
  ...getNavTabsComponent({
    borderAround: {
      borderColor: colors.primary[500],
      borderRadius: '0.25rem',
      color: `${colors.primary[500]}7f`, // 7f suffix is used to give 50% opacity
      fontSize: '1.125rem',
      fontWeight: '900',
      _activeLink: {
        background: colors.primary[100],
        color: colors.primary[500],
      },
    },
    borderBottom: {
      borderColor: colors.primary[100],
      color: `${colors.primary[500]}99`, // 99 suffix is used to give 60% opacity
      fontSize: '1rem',
      fontWeight: 400,
      _activeLink: {
        borderColor: `${colors.secondary.electric[100]}99`, // 99 suffix is used to give 60% opacity
        color: colors.primary[500],
      },
    },
  }),
};
