import { Box } from '@chakra-ui/react';

import { space } from '../../theme/shared';

import { EmojiProps } from './types';

export const Emoji = ({
  symbol,
  size = 'md',
  'aria-label': label,
}: EmojiProps): React.ReactElement => (
  <Box as="span" role="img" aria-label={label} fontSize={space[size]}>
    {symbol}
  </Box>
);
