import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql } from '@apollo/client';
import {
  Card,
  Typography,
  Markdown,
  CardPalette,
} from '@eucalyptusvc/design-system';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { DoctorBio } from '@customer-frontend/doctor';
import { DoctorLetterComponentFragment } from '@customer-frontend/graphql-types';

export type DoctorLetterProps = {
  doctor: DoctorLetterComponentFragment;
  patientNotes: string;
  prescribedDate: string;
  palette?: {
    bio?: CardPalette;
    letter?: CardPalette;
  };
};

export function DoctorLetter({
  doctor,
  patientNotes,
  prescribedDate,
  palette,
}: DoctorLetterProps): ReactElement {
  const { brand } = getConfig();

  return (
    <section className="space-y-8">
      <header className="space-y-2">
        <Typography isBold size="xl">
          <FormattedMessage
            defaultMessage="A letter from {doctorName}"
            values={{ doctorName: doctor.shortClinicianName }}
          />
        </Typography>

        <div>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Prescribed by <strong>{clinicianName}</strong>"
              values={{
                clinicianName: doctor.fullClinicianName,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>

          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="on <strong>{prescribedDate}</strong>"
              values={{
                prescribedDate: formatDate(
                  mapBrandToAdaptersBrand(brand),
                  prescribedDate,
                  { dateStyle: 'medium' },
                ),
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </div>
      </header>

      <DoctorBio doctor={doctor} palette={palette?.bio} />

      <Card palette={palette?.letter}>
        <Markdown formatWhitespace src={patientNotes} />
      </Card>
    </section>
  );
}

DoctorLetter.fragment = gql`
  fragment DoctorLetterComponent on User {
    id
    shortClinicianName
    fullClinicianName
    ...DoctorBioComponent
  }

  ${DoctorBio.fragment}
`;
