import React from 'react';
import {
  JuniperQuizCompletePageQuery,
  QuizApplication,
} from '@customer-frontend/graphql-types';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import { useQuizApplication } from '@customer-frontend/services';

export const CompleteQuiz = ({
  onQuizApplicationLoaded,
  asyncConsultsEnabled = false,
  asyncEligible = false,
  experimentQuizCompleteLoadingTimeEnabled = false,
  quizCompleteData,
}: {
  onQuizApplicationLoaded: (
    quizApplication: QuizApplication,
    asyncConsultsEnabled: boolean,
    asyncEligible: boolean,
  ) => void;
  asyncConsultsEnabled: boolean;
  asyncEligible: boolean;
  experimentQuizCompleteLoadingTimeEnabled?: boolean;
  quizCompleteData?: JuniperQuizCompletePageQuery;
}): React.ReactElement => {
  const { quizApplicationId } = useParams<{ quizApplicationId: string }>();
  const { data: quizApplicationData } = useQuizApplication(
    quizApplicationId,
    experimentQuizCompleteLoadingTimeEnabled,
  );

  const quizApplication = experimentQuizCompleteLoadingTimeEnabled
    ? quizCompleteData?.getQuizApplication
    : quizApplicationData?.getQuizApplication;

  React.useEffect(() => {
    if (!quizApplication) {
      return;
    }

    onQuizApplicationLoaded(
      quizApplication as unknown as QuizApplication,
      asyncConsultsEnabled,
      asyncEligible,
    );
  }, [
    quizApplication,
    onQuizApplicationLoaded,
    asyncConsultsEnabled,
    asyncEligible,
  ]);

  return (
    <div className="flex justify-center p-5">
      <LoadingSpinner />
    </div>
  );
};
